import { collabodocTheme as collabodocComponentLibraryTheme } from "@collabodoc/component-library";
import logo from "../Images/logo/collabodoc_light.png";
import MedicalRoundTheme from "./MedicalRoundTheme";

const collabodocTheme: MedicalRoundTheme = {
  ...collabodocComponentLibraryTheme,
  logo: logo,
  card: {
    cardHeader: {
      backgroundColor: collabodocComponentLibraryTheme.colors.primary,
      color: "white",
    },
    selectedCard: {
      backgroundColor: collabodocComponentLibraryTheme.colors.tertiary,
      color: collabodocComponentLibraryTheme.colors.white,
      borderColor: collabodocComponentLibraryTheme.colors.secondary,
    },
    message: {
      selfBackground: collabodocComponentLibraryTheme.colors.secondary,
      otherBackground: collabodocComponentLibraryTheme.colors.disabled,
    },
    hover: {
      backgroundColor: collabodocComponentLibraryTheme.colors.secondary,
    },
  },
  filter: {
    backgroundColor: collabodocComponentLibraryTheme.colors.tertiary,
    color: collabodocComponentLibraryTheme.colors.primary,
  },
  pagination: {
    active: collabodocComponentLibraryTheme.colors.focus,
    dark: collabodocComponentLibraryTheme.colors.primary,
    light: collabodocComponentLibraryTheme.colors.white,
  },
  notification: {
    unread: collabodocComponentLibraryTheme.colors.tertiary,
  },
  dropdown: {
    groupHeaderColor: collabodocComponentLibraryTheme.colors.secondary,
  },
  issueCardHeader: {
    backgroundColor: collabodocComponentLibraryTheme.colors.secondary,
    color: collabodocComponentLibraryTheme.colors.primary,
  },
  issueCardRow: {
    selected: collabodocComponentLibraryTheme.colors.tertiary,
    hover: collabodocComponentLibraryTheme.colors.secondary,
  },
};

export default collabodocTheme;

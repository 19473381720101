import { getClinicRole } from "@collabodoc/component-library";
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { GlobalContext } from "../../context/GlobalContext";
import { SITE_URLS } from "../../enums/Urls";
import { getDefaultFilters } from "../../hooks/usePatientListFilters";

const PatientListRedirect = () => {
  const { oidcUser } = useContext(GlobalContext);
  const clinicRole = getClinicRole(oidcUser.role);
  const url = `${SITE_URLS.PATIENT_LIST}?${getDefaultFilters(clinicRole)}`;
  return <Navigate to={url} replace />;
};

export default PatientListRedirect;

import { SearchBarWithLabel } from "@collabodoc/component-library";
import React, {
  ForwardedRef,
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { generatePath, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { device } from "../Content/Style/devices";
import { GlobalContext } from "../context/GlobalContext";
import { PatientClient, PatientSearchResult } from "../apiClient";
import { SITE_URLS } from "../enums/Urls";
import useClickOutside from "../hooks/useClickOutside";

interface PatientSearchProps {
  handleToggleShowPatientSearch: () => void;
  setFocus?: boolean;
}

const defaultTake = 5;

const PatientSearch = forwardRef(
  (
    { handleToggleShowPatientSearch, setFocus }: PatientSearchProps,
    searchIconRef: ForwardedRef<SVGElement>,
  ) => {
    const navigate = useNavigate();
    const { accessToken, residentDepartments } = useContext(GlobalContext);
    const searchResultRef = useRef<HTMLDivElement>(null);
    const [searchString, setSearchString] = useState("");
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [take, setTake] = useState(defaultTake);
    const [isLoading, setIsLoading] = useState(false);
    const [{ patients = [], count = 0 }, setData] =
      useState<PatientSearchResult>({ count: 0, patients: [] });

    useEffect(() => {
      setFocus && inputRef?.current?.focus();
    }, [setFocus]);

    const inputRef = useClickOutside<HTMLInputElement>((e) => {
      if (!searchResultRef?.current?.contains(e.target as HTMLElement)) {
        setSearchString("");
        setData({ count: 0, patients: [] });
        setCurrentIndex(-1);

        if (
          typeof searchIconRef !== "function" &&
          !searchIconRef?.current?.contains(e.target as HTMLElement)
        ) {
          handleToggleShowPatientSearch();
        }
      }
    });

    const handleSearch = (str: string, newTake: number) => {
      if (str) {
        setIsLoading(true);

        const client = new PatientClient({ accessToken });
        client
          .getPatients(
            residentDepartments.map(
              (residentDepartment) => residentDepartment.id,
            ),
            newTake,
            { searchString: str },
          )
          .then((data) => {
            setIsLoading(false);
            setData(data);
          });
      } else {
        setData({ count: 0, patients: [] });
      }

      setSearchString(str);
      setCurrentIndex(-1);
      setTake(newTake);
    };

    const handleClickSearchResult = (id: string) => {
      navigate({
        pathname: generatePath(SITE_URLS.PATIENT_VIEW, {
          patientId: id,
        }),
      });

      handleToggleShowPatientSearch();
      setSearchString("");
      setData({ count: 0, patients: [] });
      setCurrentIndex(-1);
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
      if (searchString.length === 0) {
        return;
      }

      if (e.key === "ArrowUp") {
        if (take === 5) {
          e.preventDefault();
        }

        if (patients.length > 0) {
          setCurrentIndex((prevIndex) =>
            prevIndex <= 0 ? patients.length - 1 : prevIndex - 1,
          );
        }
      } else if (e.key === "ArrowDown") {
        if (take === 5) {
          e.preventDefault();
        }

        if (patients.length > 0) {
          setCurrentIndex((prevIndex) =>
            prevIndex >= patients.length - 1 ? 0 : prevIndex + 1,
          );
        }
      } else if (e.key === "Enter" && currentIndex !== -1) {
        if (take === 5) {
          e.preventDefault();
        }
        handleClickSearchResult(patients[currentIndex].id);
      }
    };

    const handleShowMoreResults = () => {
      handleSearch(searchString, take + defaultTake);
    };

    return (
      <SearchBarWrapper onKeyDown={handleKeyDown}>
        <SearchBarWithLabel
          ref={inputRef}
          placeholder={"Sök patient"}
          handleChange={(e) => handleSearch(e, defaultTake)}
          value={searchString}
        />
        <SearchResultWrapper
          $isOpen={searchString.length > 0}
          ref={searchResultRef}
        >
          <SearchResult>
            {patients.length > 0
              ? patients.map((patient, index) => (
                  <SearchResultItem
                    key={patient.id}
                    onClick={() => handleClickSearchResult(patient.id)}
                    onMouseEnter={() => setCurrentIndex(index)}
                    $isActive={index === currentIndex}
                  >
                    {patient.name}, <i>{patient.personId}</i>
                    <SearchResultResidentDepartmentText>
                      {
                        residentDepartments.find(
                          (residentDepartment) =>
                            residentDepartment.id ===
                            patient.residentDepartmentId,
                        )?.name
                      }
                      , {patient.unitName}
                    </SearchResultResidentDepartmentText>
                  </SearchResultItem>
                ))
              : !isLoading && (
                  <span>
                    inga resultat för <b>{searchString}</b>
                  </span>
                )}
          </SearchResult>
          {count > patients.length && (
            <ShowMoreResultsButton onClick={handleShowMoreResults}>
              Visa fler... ({patients.length} av {count})
            </ShowMoreResultsButton>
          )}
        </SearchResultWrapper>
      </SearchBarWrapper>
    );
  },
);

export default PatientSearch;

const SearchBarWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;

  span {
    z-index: 0;
  }

  input {
    border-radius: 8px;
  }
`;

const SearchResultWrapper = styled.div<{ $isOpen: boolean }>`
  display: ${(props) => (props.$isOpen ? "flex" : "none")};
  flex-direction: column;
  position: absolute;
  top: 105%;
  width: 100%;
  color: #000;
  max-height: 45vh;
  overflow-y: auto;
  z-index: 999;
  border: ${(props) =>
    props.$isOpen ? `1px solid ${props.theme.colors.primary}` : "none"};
  background-color: #fff;
  border-radius: 8px;

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 8px;

    :active {
      background-color: #a8a8a8;
    }
  }
`;

const SearchResult = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;

  span {
    cursor: pointer;
    border-bottom: 1px solid #c8c8c8;
    padding: 0.75rem;

    &:last-child {
      border: none;
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.tertiary};
    }

    @media ${device.tablet} {
      padding: 0.5rem;
    }
  }
`;

const SearchResultItem = styled.span<{ $isActive: boolean }>`
  background-color: ${(props) =>
    props.$isActive && props.theme.colors.tertiary};
`;

const SearchResultResidentDepartmentText = styled.div`
  font-size: 12px;
`;

const ShowMoreResultsButton = styled.button`
  display: flex;
  justify-content: center;
  padding: 0.75rem;
  border: none;
  border-top: 1px solid #c8c8c8;

  &:hover {
    background-color: ${(props) => props.theme.colors.tertiary};
  }

  @media ${device.tablet} {
    padding: 0.5rem;
  }
`;

import { getClinicRole, SecondaryButton } from "@collabodoc/component-library";
import { faNoteSticky } from "@fortawesome/free-regular-svg-icons";
import {
  faCalendar,
  faChevronLeft,
  faNoteSticky as faNoteStickySolid,
  faUserPen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { device } from "../../Content/Style/devices";
import { GlobalContext } from "../../context/GlobalContext";
import { PatientListContext } from "../../context/PatientListContext";
import { EditPatientModal } from "./EditPatientModal";
import { NoteModal } from "./NoteModal";
import { PatientViewContext } from "./PatientView";
import ReminderModal from "./ReminderModal";

interface PatientInfoProps {
  reminderModalOpen: boolean;
  setReminderModalOpen: (value: boolean) => void;
}

export const ButtonRow = ({
  reminderModalOpen,
  setReminderModalOpen,
}: PatientInfoProps) => {
  const { oidcUser } = useContext(GlobalContext);
  const { patientListUrl } = useContext(PatientListContext);
  const { patient } = useContext(PatientViewContext);
  const location = useLocation();
  const [editPatientModalOpen, setEditPatientModalOpen] = useState(false);
  const [notePatientModalOpen, setNotePatientModalOpen] = useState(
    location.state?.noteModalOpen,
  );
  const [showReminderIndicator, setShowReminderIndicator] = useState(false);
  const [showPrivateNoteIndicator, setShowPrivateNoteIndicator] =
    useState(false);
  const navigate = useNavigate();

  const clinicRole = getClinicRole(oidcUser?.role);

  useEffect(() => {
    const currentReminders = patient.reminders.currentReminders.length > 0;

    setShowPrivateNoteIndicator(Boolean(patient?.note?.text));
    setShowReminderIndicator(currentReminders);
  }, [patient]);

  const NoteIcon = patient?.note?.text ? faNoteStickySolid : faNoteSticky;

  const isLaptopM = useMediaQuery({ query: device.laptopM });

  return (
    <>
      <PatientInfoWrapper>
        <LeftContent>
          <StyledSecondaryButton onClick={() => navigate(patientListUrl)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            {!isLaptopM && <span>Till patientlistan</span>}
          </StyledSecondaryButton>
        </LeftContent>

        <RightContent>
          <NotificationIconWrapper>
            <SecondaryButton onClick={() => setNotePatientModalOpen(true)}>
              <FontAwesomeIcon icon={NoteIcon} />
              {!isLaptopM && " Privat anteckning"}
            </SecondaryButton>
            <RedIndicator $showIndicator={showPrivateNoteIndicator} />
          </NotificationIconWrapper>
          {clinicRole && (
            <NotificationIconWrapper>
              <SecondaryButton onClick={() => setReminderModalOpen(true)}>
                <FontAwesomeIcon icon={faCalendar} />{" "}
                {!isLaptopM && "Påminnelser"}
              </SecondaryButton>
              <RedIndicator $showIndicator={showReminderIndicator} />
            </NotificationIconWrapper>
          )}
          {patient.userCanEdit && (
            <SecondaryButton onClick={() => setEditPatientModalOpen(true)}>
              <FontAwesomeIcon icon={faUserPen} />{" "}
              {!isLaptopM && "Redigera patient"}
            </SecondaryButton>
          )}
        </RightContent>
      </PatientInfoWrapper>
      <EditPatientModal
        showModal={editPatientModalOpen}
        hideModal={() => setEditPatientModalOpen(false)}
      />
      <NoteModal
        showModal={notePatientModalOpen}
        hideModal={() => setNotePatientModalOpen(false)}
      />
      <ReminderModal
        showModal={reminderModalOpen}
        setShowModal={setReminderModalOpen}
      />
    </>
  );
};

const LeftContent = styled.div`
  display: flex;
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  align-self: center;

  > svg:not(:only-child) {
    margin-right: 4px;
  }
`;

const RightContent = styled.div`
  display: flex;
  gap: 8px;
`;

const PatientInfoWrapper = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > button:first-of-type {
    white-space: nowrap;

    > svg {
      margin-right: 4px;
    }
  }

  @media ${device.tablet} {
    gap: 10px;
  }
`;

const NotificationIconWrapper = styled.div`
  position: relative;
`;

const RedIndicator = styled.div<{ $showIndicator: boolean }>`
  display: ${({ $showIndicator }) => !$showIndicator && "none"};
  position: absolute;
  top: -5px;
  right: 5px;
  padding: 5px;
  background-color: #ff3030;
  border-radius: 50%;
  border: 1px solid #0d3050;
`;

import React, { MouseEventHandler } from "react";
import { PatientListItem } from "../../apiClient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { PrimaryButton } from "@collabodoc/component-library";

interface EntityInformationProps {
  count: number;
  firstListItem: PatientListItem;
  isCollapsed: boolean;
  handleCollapse: MouseEventHandler<HTMLButtonElement>;
}

export const CollapsableRowEntityInformation = ({
  count,
  firstListItem,
  isCollapsed,
  handleCollapse,
}: EntityInformationProps): React.ReactElement => {
  if (count > 1) {
    return (
      <SmallPrimaryButton onClick={handleCollapse}>
        {isCollapsed ? `Visa ${count}` : `Dölj`}{" "}
        <FontAwesomeIcon icon={isCollapsed ? faChevronDown : faChevronUp} />
      </SmallPrimaryButton>
    );
  }
  return <span>{firstListItem.itemName}</span>;
};

const SmallPrimaryButton = styled(PrimaryButton)`
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 6rem;
  padding: 0.25rem 0.5rem 0.25rem 0.6rem;
  svg {
    margin-left: 0.5rem;
  }
`;

import {
  HttpTransportType,
  HubConnectionBuilder,
  HubConnectionState,
} from "@microsoft/signalr";

export let hasBeenDisconnected = false;
export const hubConnection = new HubConnectionBuilder()
  .withUrl("/signalrhub", {
    skipNegotiation: true,
    transport: HttpTransportType.WebSockets,
  })
  .withAutomaticReconnect()
  .build();

hubConnection.onreconnecting(() => (hasBeenDisconnected = true));

document.addEventListener("visibilitychange", async () => {
  if (
    !document.hidden &&
    hubConnection.state === HubConnectionState.Disconnected
  ) {
    await hubConnection.start();
  }
});

hubConnection.start();

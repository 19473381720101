import { CheckboxWithLabel, P } from "@collabodoc/component-library";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import styled from "styled-components";
import usePatientListFilters, {
  FilterOptionType,
} from "../../hooks/usePatientListFilters";

type MultiFilterProps = {
  showFilterDefault: boolean;
  showAllOption: boolean;
  options: FilterOptionType[];
};

export const MultiFilter = ({
  showFilterDefault = true,
  showAllOption = false,
  options = [],
}: MultiFilterProps) => {
  const [showFilter, setShowFilter] = useState(showFilterDefault);

  const {
    areAllSelected,
    toggleAllSelected,
    isFilterSelected,
    toggleFilterSelected,
  } = usePatientListFilters();

  const header = options[0]?.header;
  const allName = options[0]?.name;
  if (!options.length) return <></>;
  return (
    <>
      <Header onClick={() => setShowFilter(!showFilter)}>
        <FilterHeader>{header}</FilterHeader>
        <FontAwesomeIcon icon={showFilter ? faChevronUp : faChevronDown} />
      </Header>
      <FilterOption hidden={!showFilter}>
        {showAllOption && (
          <>
            <CheckboxWithLabel
              label={"Alla"}
              checked={areAllSelected(allName, options)}
              onChange={() => toggleAllSelected(allName, options)}
            />
            <Hr />
          </>
        )}
        <Ul>
          {options.map(({ name, value, label, children = [] }) => (
            <Li key={`${name}-${value}`}>
              <CheckboxWithLabel
                label={label}
                value={value}
                checked={isFilterSelected(name, value)}
                onChange={() =>
                  toggleFilterSelected(
                    name,
                    value,
                    children.map(({ name, value }) => ({ name, value })),
                  )
                }
              />
              {children.length > 0 && (
                <MultiFilterChildren
                  parentId={value}
                  parentName={name}
                  children={children}
                  showChildrenDefault={options.length === 1}
                />
              )}
            </Li>
          ))}
        </Ul>
      </FilterOption>
    </>
  );
};

const Header = styled.div`
  background-color: ${(props) => props.theme.filter.backgroundColor};
  padding: 6px;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.colors.secondary};
  display: flex;
  cursor: pointer;

  > svg {
    color: ${(props) => props.theme.colors.primary};
    margin-top: 4px;
    margin-right: 3px;
  }
`;

const FilterHeader = styled(P)`
  color: ${(props) => props.theme.filter.color};
  margin-bottom: 0;
  padding-right: 15px;
  margin-left: 5px;
`;

const FilterOption = styled.div`
  margin-left: 12px;
  margin-right: 5px;
`;

type MultiFilterChildrenProps = {
  parentId: string | number;
  parentName: string;
  children: FilterOptionType[];
  showChildrenDefault: boolean;
};
const MultiFilterChildren = ({
  parentId,
  parentName,
  children,
  showChildrenDefault,
}: MultiFilterChildrenProps) => {
  const [showChildren, setShowChildren] = useState(showChildrenDefault);
  const { isFilterSelected, toggleFilterSelected } = usePatientListFilters();

  return (
    <>
      <ShowChildrenButton onClick={() => setShowChildren(!showChildren)}>
        <FontAwesomeIcon icon={showChildren ? faChevronUp : faChevronDown} />
      </ShowChildrenButton>
      <UlChild>
        {showChildren &&
          children.map(({ name, value, label }) => (
            <Li key={`${name}-${value}`}>
              <CheckboxWithLabel
                label={label}
                value={value}
                checked={isFilterSelected(name, value)}
                onChange={() => {
                  toggleFilterSelected(name, value);
                  if (!isFilterSelected(parentName, parentId)) {
                    toggleFilterSelected(parentName, parentId);
                  }
                }}
              />
            </Li>
          ))}
      </UlChild>
    </>
  );
};

const ShowChildrenButton = styled.button`
  padding: 0;
  margin-left: 0.75rem;
  user-select: none;
  border: none;
  background: none;
`;

const Ul = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;
const Li = styled.li`
  label {
    display: inline-block;
  }
`;
const UlChild = styled(Ul)`
  padding-left: 1rem;
`;

const Hr = styled.hr`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

import {
  CheckboxWithLabel,
  TextInputWithLabelAndError,
} from "@collabodoc/component-library";
import { Dispatch, SetStateAction, useContext } from "react";
import styled from "styled-components";
import { GlobalContext } from "../../context/GlobalContext";

interface MeasureSectionProps {
  measures: { measureTemplateId: number; phrase: string | undefined }[];
  setMeasures: Dispatch<
    SetStateAction<{ measureTemplateId: number; phrase: string | undefined }[]>
  >;
}

const MeasureSection = ({ measures, setMeasures }: MeasureSectionProps) => {
  const { measureTemplates = [] } = useContext(GlobalContext);

  const handleMeasureState = (templateId: number) => {
    setMeasures((prevState) =>
      prevState.map((x) => x.measureTemplateId).includes(templateId)
        ? measures.filter((m) => m.measureTemplateId !== templateId)
        : [
            ...prevState,
            {
              measureTemplateId: templateId,
              phrase: "",
            },
          ],
    );
  };

  const handleFormChange = (templateId: number, phrase = "", role = 1) => {
    //Update measure phrase if measure already exists, else add new measure
    if (measures.some((m) => m.measureTemplateId === templateId)) {
      let arr = measures.filter((m) => m.measureTemplateId !== templateId);
      arr.push({
        measureTemplateId: templateId,
        phrase: phrase,
      });

      setMeasures(arr);
    } else {
      setMeasures((prevState) => [
        ...prevState,
        { measureTemplateId: templateId, phrase: phrase },
      ]);
    }
  };

  return (
    <Measures>
      {measureTemplates.map((measureTemplate) => (
        <MeasureWrapper key={measureTemplate.id}>
          <CheckboxWithLabel
            label={measureTemplate.name}
            checked={measures
              .map((x) => x.measureTemplateId)
              .includes(measureTemplate.id)}
            onChange={() => handleMeasureState(measureTemplate.id)}
          />
          {measures
            .map((x) => x.measureTemplateId)
            .includes(measureTemplate.id) && (
            <TextInputWithLabelAndError
              value={
                measures.find((m) => m.measureTemplateId === measureTemplate.id)
                  ?.phrase || ""
              }
              label={""}
              handleChange={(e) => handleFormChange(measureTemplate.id, e)}
            />
          )}
        </MeasureWrapper>
      ))}
    </Measures>
  );
};

const Measures = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  word-wrap: break-word;
`;

const MeasureWrapper = styled.div`
  > label {
    color: #0d3050;
    margin: 0;

    > span::before {
      border-radius: 3px;
    }
  }

  > input {
    margin-bottom: 5px;
    border-radius: 3px;
  }
`;
export default MeasureSection;

import { H4 } from "@collabodoc/component-library";
import { Card } from "react-bootstrap";
import styled from "styled-components";
import { device } from "./devices";

const CardComponent = styled(Card)`
  border: none;
  border-radius: 10px;
  margin-bottom: 15px;
`;

const CardHeader = styled(Card.Header)`
  padding: 7px 15px 5px;
  border-top: none;
  background-color: ${({ theme }) => theme.card.cardHeader.backgroundColor};

  :first-child {
    border-radius: 10px 10px 0px 0px;
  }
`;

const CardHeaderText = styled(H4)`
  display: inline;
  color: ${({ theme }) => theme.card.cardHeader.color};
`;

const CardBody = styled(Card.Body)`
  padding: 0.75rem;
  border: none;

  @media ${device.tablet} {
    padding: 0;
  }
`;

const CardFooter = styled(Card.Footer)`
  background-color: #e4e4e4;

  :last-child {
    border-radius: 0 0 10px 10px;
  }
`;

export { CardComponent, CardHeader, CardHeaderText, CardBody, CardFooter };

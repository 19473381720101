import Personnummer from "personnummer";

const personNumberOptions = {
  allowCoordinationNumber: false,
  allowInterimNumber: false,
};

export const isValidPersonalNumber = (personId: string) =>
  Personnummer.valid(personId, personNumberOptions);

export const getFormattedPersonId = (personId: string) =>
  isValidPersonalNumber(personId)
    ? Personnummer.parse(personId).format(true)
    : personId.trim();

import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  CheckboxWithLabel,
  getClinicRole,
  H4,
  PrimaryButton,
  SecondaryButton,
  TextAreaWithLabelAndError,
} from "@collabodoc/component-library";
import {
  faCalendarDay,
  faCheck,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatISO } from "date-fns";
import sv from "date-fns/locale/sv";
import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { CardHeader, CardHeaderText } from "../../Content/Style/cardstyles";
import { device } from "../../Content/Style/devices";
import { GlobalContext } from "../../context/GlobalContext";
import {
  ACTIVE_REMINDER_TAB,
  USER_ROLE_NAMES,
  USER_ROLES,
} from "../../enums/enums";
import { formatDateTime } from "../../utils/dateUtils";
import { PatientViewContext } from "./PatientView";
import { useLocation } from "react-router-dom";
import { ReminderClient, ReminderModel } from "../../apiClient";

const daysUntilMinDate = 0;
const daysUntilMaxDate = 400;

interface ReminderModalProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}

const ReminderModal = ({ showModal, setShowModal }: ReminderModalProps) => {
  const { patient, reFetchPatient } = useContext(PatientViewContext);
  const location = useLocation();
  const today = new Date();
  const minDate = new Date();
  const maxDate = new Date();
  minDate.setDate(today.getDate() + daysUntilMinDate);
  maxDate.setDate(today.getDate() + daysUntilMaxDate);
  const { oidcUser, accessToken } = useContext(GlobalContext);
  const [selectedDate, setSelectedDate] = useState(minDate);
  const [reminderText, setReminderText] = useState("");
  const [showOtherRolesReminders, setShowOtherRolesReminders] = useState(
    location.state?.isOtherRoleReminder,
  );

  const [activeReminderTab, setActiveReminderTab] = useState(
    ACTIVE_REMINDER_TAB.ALL_REMINDERS_TAB,
  );
  const clinicRole = getClinicRole(oidcUser?.role);

  const createReminder = async () => {
    const data = {
      message: reminderText,
      date: formatISO(selectedDate, { representation: "date" }),
    };
    const client = new ReminderClient({ accessToken });
    client.postReminder(patient.id, data).then(() => {
      reFetchPatient();
      setReminderText("");
      setSelectedDate(minDate);
      setActiveReminderTab(ACTIVE_REMINDER_TAB.ALL_REMINDERS_TAB);
    });
  };

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <StyledModalHeader>
        <CurrentSectionWrapper>
          <StyledCardHeader
            onClick={() =>
              setActiveReminderTab(ACTIVE_REMINDER_TAB.ALL_REMINDERS_TAB)
            }
            $isSelected={
              activeReminderTab === ACTIVE_REMINDER_TAB.ALL_REMINDERS_TAB
            }
          >
            <StyledCardHeaderText
              $isSelected={
                activeReminderTab === ACTIVE_REMINDER_TAB.ALL_REMINDERS_TAB
              }
            >
              Påminnelser
            </StyledCardHeaderText>
          </StyledCardHeader>
          <StyledCardHeader
            onClick={() =>
              setActiveReminderTab(ACTIVE_REMINDER_TAB.NEW_REMINDER_TAB)
            }
            $isSelected={
              activeReminderTab === ACTIVE_REMINDER_TAB.NEW_REMINDER_TAB
            }
          >
            <StyledCardHeaderText
              $isSelected={
                activeReminderTab === ACTIVE_REMINDER_TAB.NEW_REMINDER_TAB
              }
            >
              Ny påminnelse
            </StyledCardHeaderText>
          </StyledCardHeader>
        </CurrentSectionWrapper>
      </StyledModalHeader>
      <ModalBody>
        <BodyColumn
          $isSelected={
            activeReminderTab === ACTIVE_REMINDER_TAB.ALL_REMINDERS_TAB
          }
        >
          <>
            <ReminderList
              heading={"Aktuella"}
              reminders={patient.reminders.currentReminders}
              isCurrentReminder={true}
            />
            <ReminderList
              heading={"Kommande"}
              reminders={patient.reminders.upcomingReminders}
              isCurrentReminder={false}
            />
            <ReminderList
              heading={"Avslutade"}
              reminders={patient.reminders.handledReminders}
              isCurrentReminder={false}
              isHandledReminder={true}
            />
            {showOtherRolesReminders && (
              <>
                <LineSeparator />
                <ReminderList
                  heading={"Aktuella (andra roller)"}
                  reminders={patient.reminders.otherRolesCurrentReminders}
                  isCurrentReminder={true}
                />
                <ReminderList
                  heading={"Kommande (andra roller)"}
                  reminders={patient.reminders.otherRolesUpcomingReminders}
                  isCurrentReminder={false}
                />
                <ReminderList
                  heading={"Avslutade (andra roller)"}
                  reminders={patient.reminders.otherRolesHandledReminders}
                  isCurrentReminder={false}
                  isHandledReminder={true}
                />
              </>
            )}
          </>
        </BodyColumn>
        <Wrapper
          $isSelected={
            activeReminderTab === ACTIVE_REMINDER_TAB.NEW_REMINDER_TAB
          }
        >
          <div>
            <StyledInputLabel>Datum</StyledInputLabel>
            <DatePickerWrapper>
              <DatePicker
                selected={selectedDate}
                onChange={(date: Date) => setSelectedDate(date)}
                minDate={minDate}
                maxDate={maxDate}
                locale={sv}
                inline
              />
            </DatePickerWrapper>
          </div>
          <small>
            OBS dina påminnelser syns enbart för användare med rollen{" "}
            {clinicRole &&
              USER_ROLE_NAMES[USER_ROLES[clinicRole]].toLowerCase()}
            .
          </small>
          <div>
            <StyledInputLabel>Meddelande</StyledInputLabel>
            <TextAreaWithLabelAndErrorWrapper>
              <TextAreaWithLabelAndError
                label={""}
                value={reminderText}
                handleChange={setReminderText}
                maxLength={300}
                minRows={2}
                maxRows={3}
                resizeHandles={false}
              />
            </TextAreaWithLabelAndErrorWrapper>
          </div>
        </Wrapper>
      </ModalBody>
      <ModalFooter>
        <SecondaryButton onClick={() => setShowModal(false)}>
          {activeReminderTab === ACTIVE_REMINDER_TAB.NEW_REMINDER_TAB &&
            "Avbryt"}
          {activeReminderTab === ACTIVE_REMINDER_TAB.ALL_REMINDERS_TAB &&
            "Stäng"}
        </SecondaryButton>
        {activeReminderTab === ACTIVE_REMINDER_TAB.ALL_REMINDERS_TAB && (
          <CheckboxWithLabelWrapper>
            <CheckboxWithLabel
              onChange={() =>
                setShowOtherRolesReminders(!showOtherRolesReminders)
              }
              label={"Inkludera andra rollers påminnelser"}
              checked={showOtherRolesReminders}
            />
          </CheckboxWithLabelWrapper>
        )}
        {activeReminderTab === ACTIVE_REMINDER_TAB.NEW_REMINDER_TAB && (
          <PrimaryButton onClick={createReminder} disabled={!reminderText}>
            Skapa påminnelse
          </PrimaryButton>
        )}
      </ModalFooter>
    </Modal>
  );
};

const StyledModalHeader = styled(Modal.Header)`
  padding-bottom: 0;
`;

const CurrentSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  gap: 8px;
`;

const StyledCardHeader = styled(CardHeader)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px 15px 0 0;
  padding: 12px 15px;
  flex: 1;
  text-align: center;
  background-color: ${({ $isSelected }) => !$isSelected && "#fff"};
  border: ${({ $isSelected, theme }) =>
    !$isSelected && `1px solid ${theme.colors.primary}`};
  border-bottom: ${({ $isSelected }) => !$isSelected && `none`};
  cursor: pointer;
`;

const StyledCardHeaderText = styled(CardHeaderText)<{ $isSelected: boolean }>`
  margin: 0;
  color: ${({ $isSelected, theme }) => !$isSelected && theme.colors.primary};

  @media ${device.tablet} {
    word-break: break-word;
    font-size: 14px;
  }
`;

const ModalBody = styled(Modal.Body)`
  display: flex;
  gap: 16px;
  padding: 0;

  @media only screen and (max-width: 991px) {
    flex-direction: column;
  }
`;

const BodyColumn = styled.section<{ $isSelected: boolean }>`
  display: ${({ $isSelected }) => ($isSelected ? "flex" : "none")};
  flex: 1;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  overflow: auto;
  max-height: 60vh;
`;

const LineSeparator = styled.div`
  border-top: 1px solid #333;
  margin: 14px 0;
`;

const CheckboxWithLabelWrapper = styled.div`
  > label {
    margin: 0;
  }
`;

const Wrapper = styled.div<{ $isSelected: boolean }>`
  display: ${({ $isSelected }) => ($isSelected ? "flex" : "none")};
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 1rem;
  gap: 0.5rem;
`;

const DatePickerWrapper = styled.div`
  .react-datepicker,
  .react-datepicker__month-container {
    width: 100%;
    font-size: clamp(0.8em, 1.2vw, 1.1em);

    @media only screen and (max-width: 991px) {
      font-size: 1.2rem;
    }
  }

  .react-datepicker__navigation-icon {
    top: 7px;
  }

  .react-datepicker__current-month {
    color: ${({ theme }) => theme.card.cardHeader.backgroundColor};
  }

  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.card.cardHeader.backgroundColor};
  }

  .react-datepicker__day--selected:hover {
    filter: grayscale(0.35);
  }

  .react-datepicker__day--keyboard-selected {
    color: #fff;
  }
`;

const StyledInputLabel = styled(H4)`
  color: ${({ theme }) => theme.card.cardHeader.backgroundColor};
`;

const TextAreaWithLabelAndErrorWrapper = styled.div`
  > textarea {
    display: block;
    border-radius: 5px;
  }
`;

const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between;

  @media ${device.tablet} {
    flex-direction: column-reverse;
    align-items: unset;
  }
`;

const dateFormatOptions = {
  weekday: "short",
  month: "short",
  day: "numeric",
} as const;

interface ReminderListProps {
  heading: string;
  reminders: ReminderModel[];
  isCurrentReminder: boolean;
  isHandledReminder?: boolean;
}
const ReminderList = ({
  heading,
  reminders,
  isCurrentReminder,
  isHandledReminder = false,
}: ReminderListProps) => {
  const { patientId, reFetchPatient } = useContext(PatientViewContext);
  const { accessToken } = useOidcAccessToken();
  const client = new ReminderClient({ accessToken });
  const deleteReminder = (reminderId: string) => {
    client.deleteReminder(patientId, reminderId).then(() => reFetchPatient());
  };

  const markReminderAsDone = (reminderId: string) => {
    client.patchReminder(patientId, reminderId).then(() => reFetchPatient());
  };

  return (
    <section>
      <H4>{heading}</H4>
      <Ul>
        {reminders.length < 1 ? (
          <ItalicText>Inga {heading.toLowerCase()} påminnelser</ItalicText>
        ) : (
          reminders.map((reminder) => (
            <Li key={reminder.id}>
              <ReminderWrapper $isCurrentReminder={isCurrentReminder}>
                <ReminderDateText>
                  <FontAwesomeIcon icon={faCalendarDay} />
                  {formatDateTime(reminder.date, dateFormatOptions)}
                </ReminderDateText>
                <div>{reminder.message}</div>
                <UserInfo>
                  <ItalicText>
                    {reminder.createdBy.name}, {reminder.createdBy.roleName}
                  </ItalicText>
                  <ItalicText>
                    {formatDateTime(reminder.timeOfCreated)}
                  </ItalicText>
                </UserInfo>
              </ReminderWrapper>
              {!isHandledReminder && (
                <IconWrapper>
                  {isCurrentReminder ? (
                    <CheckBtn onClick={() => markReminderAsDone(reminder.id)}>
                      <FontAwesomeIcon icon={faCheck} />
                    </CheckBtn>
                  ) : (
                    <TrashBtn onClick={() => deleteReminder(reminder.id)}>
                      <FontAwesomeIcon icon={faTrashCan} />
                    </TrashBtn>
                  )}
                </IconWrapper>
              )}
            </Li>
          ))
        )}
      </Ul>
    </section>
  );
};

const Ul = styled.ul`
  padding: 0;
  margin-bottom: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  justify-items: center;
`;
const Li = styled.li`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

const ReminderDateText = styled.div`
  display: flex;
  gap: 4px;
  font-weight: bold;

  > svg {
    margin-top: 3px;
  }
`;

const ReminderWrapper = styled.div<{ $isCurrentReminder: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  background-color: ${({ theme, $isCurrentReminder }) =>
    $isCurrentReminder
      ? theme.card.message.selfBackground
      : theme.card.message.otherBackground};
  padding: 8px;
  border-radius: 5px;
  gap: 6px;
`;

const UserInfo = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

const IconWrapper = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

const ItalicText = styled.span`
  font-size: small;
  font-style: italic;
`;

const CheckBtn = styled(PrimaryButton)`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  background-color: #4fd142;
  cursor: pointer;
  white-space: nowrap;
  border: none;
  padding: 0.75rem;

  &:hover {
    background-color: #4cc740;
    border: none;
  }
`;

const TrashBtn = styled(PrimaryButton)`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  background-color: #e32424;
  cursor: pointer;
  white-space: nowrap;
  border: none;
  padding: 0.75rem;

  &:hover {
    background-color: #c81e1e;
    border: none;
  }
`;

export default ReminderModal;

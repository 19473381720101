import {
  CheckboxWithLabel,
  DropDown,
  H2,
  SecondaryButton,
  SuccessButton,
  TextInputWithLabelAndError,
} from "@collabodoc/component-library";
import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { GlobalContext } from "../../context/GlobalContext";
import { PatientViewContext } from "./PatientView";
import { device } from "../../Content/Style/devices";
import { PatientClient } from "../../apiClient";
import {
  getFormattedPersonId,
  isValidPersonalNumber,
} from "../../utils/personalNumber";
import {isEmptyGuid} from "../PatientListView/CreatePatientModal";

interface EditPatientModalProps {
  showModal: boolean;
  hideModal: () => void;
}

interface FormValues {
  personId: string;
  hasPersonalNumber: boolean;
  firstName: string;
  lastName: string;
  residentDepartmentId: string;
  unitId: string | null;
  careCenterId: string;
}

export const EditPatientModal = ({
  showModal,
  hideModal,
}: EditPatientModalProps) => {
  const { patient, reFetchPatient } = useContext(PatientViewContext);
  const [formValues, setFormValues] = useState<FormValues>({
    personId: patient.personId,
    hasPersonalNumber: patient.hasPersonalNumber,
    firstName: patient.firstName,
    lastName: patient.lastName,
    residentDepartmentId: patient.residentDepartmentId,
    unitId: patient.unitId,
    careCenterId: patient.careCenterId,
  });

  const [updateError, setUpdateError] = useState("");
  const [personIdError, setPersonIdError] = useState("");
  const { showCareCenter } = useContext(GlobalContext);

  const {
    residentDepartments = [],
    careCenters = [],
    accessToken,
  } = useContext(GlobalContext);
  const client = new PatientClient({ accessToken });

  const residentDepartmentOptions = residentDepartments
    .filter((r) => r.units.length && r.userCanEditPatients)
    .map((x) => ({
      text: x.name,
      id: x.id,
    }));

  const selectedResidentDepartment = residentDepartments.find(
    (x) => x.id === formValues.residentDepartmentId,
  );

  const unitOptions =
    selectedResidentDepartment?.units.map((unit) => ({
      text: unit.name,
      id: unit.unitId,
    })) || [];

  const selectedUnit = selectedResidentDepartment?.units.find(
    (x) => x.unitId === formValues.unitId,
  );

  const careCenterOptions = careCenters
    .filter(
      (r) =>
        !selectedUnit?.defaultCareCenterId ||
        r.id === selectedUnit?.defaultCareCenterId,
    )
    .map((cc) => ({
      text: cc.name,
      id: cc.id,
    }));

  const selectedCareCenter = careCenters.find(
    (cc) => cc.id === formValues.careCenterId,
  );

  const updateForm = (newValues: Partial<FormValues>) => {
    if (newValues.residentDepartmentId) {
      newValues.unitId = "";
      newValues.careCenterId = "";
    }
    if (newValues.unitId) {
      const defaultCareCenterId = selectedResidentDepartment?.units.find(
        (u) => u.unitId === newValues.unitId,
      )?.defaultCareCenterId;
      if (defaultCareCenterId) {
        newValues.careCenterId = defaultCareCenterId;
      }
    }
    setFormValues((v) => ({ ...v, ...newValues }));
  };

  const checkPersonalNumber = (personId: string, hasPnr: boolean) => {
    updateForm({ personId: personId });
    if (!personId || (hasPnr && !isValidPersonalNumber(personId))) {
      setPersonIdError("Ogiltigt personnummer");
    } else {
      setPersonIdError("");
      client
        .getPatientByPersonId(getFormattedPersonId(personId))
        .then((existingPatient) => {
          if (!isEmptyGuid(existingPatient.id) && existingPatient.id !== patient.id) {
            setPersonIdError(
              "En patient med detta personnummer/id finns redan",
            );
          } else {
            setPersonIdError("");
          }
        })
        .catch(() => setPersonIdError(""));
    }
  };

  const toggleHasPersonalNumber = (hasPnr: boolean) => {
    updateForm({ hasPersonalNumber: hasPnr });
    setPersonIdError("");
    if (formValues.personId && formValues.personId !== "19") {
      checkPersonalNumber(formValues.personId, hasPnr);
    } else {
      updateForm({ personId: hasPnr ? "19" : "" });
    }
  };

  const handleSave = async () => {
    setUpdateError('');
    if (formValues.unitId === null) {
      setUpdateError('Patientinformationen kunde inte uppdateras');
    } else {
      const data = {
        unitId: formValues.unitId,
        personId: formValues.personId,
        firstname: formValues.firstName,
        lastname: formValues.lastName,
        hasPersonalNumber: formValues.hasPersonalNumber,
        careCenterId: formValues.careCenterId,
      };
      client
        .editPatient(patient.id, data)
        .then(() => {
          hideModal();
          reFetchPatient();
        })
        .catch((details) =>
        {
          if (details.status === 409) {
            setUpdateError('Patienten finns redan på det boendet');
          }
          else{
            setUpdateError('Patientinformationen kunde inte uppdateras')
          }
        })
    }
  };

  const reset = () => {
    setFormValues({
      personId: patient.personId,
      hasPersonalNumber: patient.hasPersonalNumber,
      firstName: patient.firstName,
      lastName: patient.lastName,
      residentDepartmentId: patient.residentDepartmentId,
      unitId: patient.unitId,
      careCenterId: patient.careCenterId,
    });
    setPersonIdError("");
    setUpdateError("");
    hideModal();
  };

  let disabled =
    !formValues.personId ||
    !formValues.firstName ||
    !formValues.lastName ||
    !formValues.residentDepartmentId ||
    !formValues.unitId ||
    Boolean(personIdError);

  return (
    <Modal show={showModal} onHide={() => reset()}>
      <Modal.Header>
        <StyledH2>Redigera patient</StyledH2>
      </Modal.Header>
      <Modal.Body>
        <ModalInputDiv>
          <ModalInputLabel>
            {formValues.hasPersonalNumber
              ? "Personnummer"
              : "Reservnummer / samordningsnummer / födelsenummer"}
          </ModalInputLabel>
          <TextInputWithLabelAndError
            label={""}
            maxLength={20}
            placeholder={
              formValues.hasPersonalNumber
                ? "ÅÅÅÅMMDD-XXXX"
                : "Reservnummer / samordningsnummer / födelsenummer"
            }
            value={formValues.personId}
            handleChange={(e) =>
              checkPersonalNumber(e, formValues.hasPersonalNumber)
            }
          />
          {personIdError && <InputError>{personIdError}</InputError>}
          <CheckboxWithLabel
            checked={!formValues.hasPersonalNumber}
            label={"Personnummer saknas"}
            onChange={() =>
              toggleHasPersonalNumber(!formValues.hasPersonalNumber)
            }
          />
        </ModalInputDiv>
        <ModalInputDiv>
          <ModalInputLabel>Förnamn</ModalInputLabel>
          <TextInputWithLabelAndError
            label={""}
            value={formValues.firstName}
            handleChange={(e) => updateForm({ firstName: e })}
          />
        </ModalInputDiv>
        <ModalInputDiv>
          <ModalInputLabel>Efternamn</ModalInputLabel>
          <TextInputWithLabelAndError
            label={""}
            value={formValues.lastName}
            handleChange={(e) => updateForm({ lastName: e })}
          />
        </ModalInputDiv>
        <ModalInputDiv>
          <ModalInputLabel>Boende</ModalInputLabel>
          <DropDown
            placeholder={"Välj boende"}
            options={residentDepartmentOptions}
            handler={(id) =>
              updateForm({ residentDepartmentId: id as string, unitId: null })
            }
            sort={true}
            selected={selectedResidentDepartment?.name}
          />
        </ModalInputDiv>
        <ModalInputDiv>
          <ModalInputLabel>Avdelning</ModalInputLabel>
          <DropDown
            placeholder={"Välj avdelning"}
            options={unitOptions}
            handler={(id) => updateForm({ unitId: id.toString() })}
            selected={selectedUnit && selectedUnit.name}
          />
        </ModalInputDiv>
        {showCareCenter && (
          <ModalInputDiv>
            <ModalInputLabel>Vårdcentral</ModalInputLabel>
            <DropDown
              placeholder={"Välj vårdcentral"}
              options={careCenterOptions}
              handler={(id) => updateForm({ careCenterId: id as string })}
              selected={selectedCareCenter?.name}
              isDisabled={!selectedUnit}
            />
          </ModalInputDiv>
        )}
        {updateError && (
          <InputErrorRed>
            {updateError}
          </InputErrorRed>
        )}
      </Modal.Body>
      <Footer>
        <SecondaryButton onClick={() => reset()}>Avbryt</SecondaryButton>
        <SuccessButton disabled={disabled} onClick={() => handleSave()}>
          Spara
        </SuccessButton>
      </Footer>
    </Modal>
  );
};

const StyledH2 = styled(H2)`
  margin: 0;
`;

const Footer = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between;

  @media ${device.tablet} {
    flex-direction: column-reverse;
    align-items: unset;

    button {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
    }
  }
`;

const ModalInputLabel = styled.strong`
  font-style: bold;
`;

const ModalInputDiv = styled.div`
  margin-bottom: 10px;
`;

const InputError = styled.i`
  font-size: 14px;
`;

const InputErrorRed = styled(InputError)`
  color: red;
`;

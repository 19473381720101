import { useEffect, useMemo, useState } from "react";
import { hubConnection } from "../utils/hubConnection";
import { HubConnectionState } from "@microsoft/signalr";
import { ResidentDepartmentModel } from "../apiClient";

const useHubConnection = (
  residentDepartments: ResidentDepartmentModel[] | undefined,
  pollIntervalMs: number = 1000,
) => {
  const [hubConnectionState, setHubConnectionState] = useState(
    hubConnection.state,
  );
  useEffect(() => {
    const intervalId = setInterval(
      () => setHubConnectionState(hubConnection.state),
      pollIntervalMs,
    );
    return () => {
      clearInterval(intervalId);
    };
  }, [pollIntervalMs]);

  const residentDepartmentIds = useMemo(
    () =>
      residentDepartments?.map((residentDepartment) => residentDepartment.id) ||
      [],
    [residentDepartments],
  );
  useEffect(() => {
    if (
      hubConnectionState === HubConnectionState.Connected &&
      residentDepartmentIds.length > 0
    ) {
      hubConnection
        .invoke(
          "AddToGroups",
          residentDepartmentIds.map((id) => id.toString()),
        )
        .then(() => {
          console.debug(
            `Subscribed to ResidentDepartmentIds ${residentDepartmentIds.join(
              ", ",
            )}`,
          );
        });
    }
  }, [hubConnectionState, residentDepartmentIds]);

  return hubConnectionState;
};

export default useHubConnection;

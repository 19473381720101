import {
  CardBody,
  CardComponent,
  CardHeader,
  CardHeaderText,
} from "../../Content/Style/cardstyles";
import React, { useContext, useState } from "react";
import { PatientViewContext } from "./PatientView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import styled from "styled-components";
import { SecondaryButton } from "@collabodoc/component-library";
import { device } from "../../Content/Style/devices";
import { useMediaQuery } from "react-responsive";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { PatientModel } from "../../apiClient";
import { CopyButton } from "./IssueContent/ContentComponents";
import { GlobalContext } from "../../context/GlobalContext";

interface PatientDetailsProps {
  handleCopyButtonClick: () => void;
  patientPersonalNumber: string;
  patient: PatientModel;
}

const PatientDetails = ({
  handleCopyButtonClick,
  patientPersonalNumber,
  patient,
}: PatientDetailsProps) => {
  const { showCareCenter } = useContext(GlobalContext);
  return (
    <>
      <PatientPropertyWrapper>
        <PatientProperty>Personnummer:</PatientProperty>
        <span>
          {patientPersonalNumber}
          <CopyButton type={"button"} onClick={handleCopyButtonClick}>
            <FontAwesomeIcon icon={faCopy} />
          </CopyButton>
        </span>
      </PatientPropertyWrapper>
      <PatientPropertyWrapper>
        <PatientProperty>Boende:</PatientProperty>
        {patient?.residentDepartmentName}
      </PatientPropertyWrapper>
      <PatientPropertyWrapper>
        <PatientProperty>Avdelning:</PatientProperty> {patient?.unitName}
      </PatientPropertyWrapper>
      {showCareCenter && (
        <PatientPropertyWrapper>
          <PatientProperty>Vårdcentral:</PatientProperty>
          {patient?.careCenterName}
        </PatientPropertyWrapper>
      )}
    </>
  );
};

const PatientInfoCard = () => {
  const { patient } = useContext(PatientViewContext);
  const patientName = patient && `${patient.firstName} ${patient.lastName}`;
  const patientPersonalNumber = patient.personId.trim();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleCollapse = () => {
    setIsCollapsed((prevState) => !prevState);
  };

  const handleCopyButtonClick = () => {
    const personalNumberToCopy = patient.hasPersonalNumber
      ? patientPersonalNumber.substring(2).replace("-", "")
      : patient.personId;
    navigator.clipboard.writeText(personalNumberToCopy);
  };

  const isTablet = useMediaQuery({ query: device.tablet });

  return (
    <StyledCardComponent>
      <CardHeader>
        <CardHeaderText>Brukarinformation</CardHeaderText>
      </CardHeader>
      <StyledCardBody>
        <NameRow>
          <PatientPropertyWrapper>
            <PatientProperty>Namn:</PatientProperty> {patientName}
          </PatientPropertyWrapper>
          {isTablet && (
            <SmallButton onClick={handleCollapse}>
              <FontAwesomeIcon
                icon={isCollapsed ? faChevronDown : faChevronUp}
              />
            </SmallButton>
          )}
        </NameRow>
        {((isTablet && !isCollapsed) || !isTablet) && (
          <PatientDetails
            handleCopyButtonClick={handleCopyButtonClick}
            patientPersonalNumber={patientPersonalNumber}
            patient={patient}
          />
        )}
      </StyledCardBody>
    </StyledCardComponent>
  );
};

export default PatientInfoCard;

const StyledCardBody = styled(CardBody)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 14px;

  @media ${device.tablet} {
    padding: 0.75rem;
    border-radius: 0 0 10px 10px;
  }
`;

export const StyledCardComponent = styled(CardComponent)`
  overflow: hidden;
  flex-basis: content;
  margin-bottom: 0;

  @media ${device.tablet} {
    margin-bottom: 12px;
  }
`;

const PatientPropertyWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
`;

const PatientProperty = styled.strong`
  margin-right: 4px;
`;

const NameRow = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const SmallButton = styled(SecondaryButton)`
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.8rem;
  width: 1.8rem;
  padding: 0.1rem 0.2rem 0.1rem 0.2rem;
  border-radius: 50%;
`;

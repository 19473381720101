import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  DangerButton,
  H2,
  SecondaryButton,
  SuccessButton,
} from "@collabodoc/component-library";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { IssueClient, IssueModel, IssueStatus } from "../../apiClient";
import { device } from "../../Content/Style/devices";

interface CloseIssueButtonProps {
  issue: IssueModel;
}

export const CloseIssueButton = ({ issue }: CloseIssueButtonProps) => {
  const { accessToken } = useOidcAccessToken();
  const [showModal, setShowModal] = useState(false);

  const unansweredMeasures = issue.content.some(
    (content) => content.measure?.isCompleted === false,
  );

  const handleYesClick = () => {
    const client = new IssueClient({ accessToken });
    client
      .status(issue.patientId, issue.issueId, { status: IssueStatus.Completed })
      .then(() => {
        setShowModal(false);
      });
  };

  return (
    <>
      <DangerButton onClick={() => setShowModal(true)}>
        Avsluta ärende
      </DangerButton>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <StyledH2>Avsluta ärende</StyledH2>
        </Modal.Header>
        <Modal.Body>
          {unansweredMeasures ? (
            <TextWrapper>
              <Icon size={"4x"} icon={faTriangleExclamation} />
              Det finns fortfarande obesvarade åtgärder på ärendet.
              <br />
              Är du säker på att du vill avsluta ärendet?
            </TextWrapper>
          ) : (
            <p>Är du säker på att du vill avsluta ärendet?</p>
          )}
        </Modal.Body>
        <ButtonDiv>
          <SecondaryButton onClick={() => setShowModal(false)}>
            Nej, avbryt
          </SecondaryButton>
          <SuccessButton onClick={handleYesClick}>Ja, avsluta</SuccessButton>
        </ButtonDiv>
      </Modal>
    </>
  );
};

const StyledH2 = styled(H2)`
  margin: 0;
`;

const TextWrapper = styled.span`
  margin-left: 5px;
  display: flex;
  align-items: center;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.danger};
  margin-right: 10px;
`;

const ButtonDiv = styled(Modal.Footer)`
    display: flex;
    justify-content: space-between;

    @media ${device.tablet} {
        flex-direction: column-reverse;
        align-items: unset;
`;

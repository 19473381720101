import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { formatDateTime } from "../../../utils/dateUtils";
import {
  MeasureModel,
  MeasuresClient,
  UserViewModel,
} from "../../../apiClient";
import styled from "styled-components";
import React, { useContext, useEffect, useState } from "react";
import {
  ContentBox,
  ContentBoxWrapper,
  ContentReceivers,
  ContentRow,
  ContentSender,
  ContentText,
  CopyButton,
} from "./ContentComponents";
import { CheckboxWithLabel } from "@collabodoc/component-library";
import { GlobalContext } from "../../../context/GlobalContext";
import { PatientViewContext } from "../PatientView";

interface MeasureProps {
  measure: MeasureModel;
  user: UserViewModel;
  timestamp: string;
  isSender: boolean;
  isIssueClosed: boolean;
}

export const Measure = ({
  measure,
  user,
  timestamp,
  isSender,
  isIssueClosed,
}: MeasureProps) => {
  const { patientId } = useContext(PatientViewContext) as { patientId: string };
  const { accessToken } = useContext(GlobalContext);
  const [isCompleted, setIsCompleted] = useState(measure.isCompleted);
  const client = new MeasuresClient({ accessToken });

  useEffect(() => {
    setIsCompleted(measure.isCompleted);
  }, [measure.isCompleted]);

  const handleToggleCompleted = () => {
    client
      .toggleMeasure(patientId, measure.issueId, measure.id, !isCompleted)
      .catch((err) => console.error(err))
      .then(() => setIsCompleted(!isCompleted));
  };
  return (
    <ContentRow $isSender={isSender}>
      <ContentBoxWrapper $isSender={isSender}>
        <CopyButton
          type={"button"}
          title={"Kopiera"}
          onClick={() =>
            navigator.clipboard.writeText(`${measure.title}\n${measure!.text}`)
          }
        >
          <FontAwesomeIcon icon={faCopy} />
        </CopyButton>
        <MeasureContentBox $isClosed={isIssueClosed} $isSender={isSender}>
          <ContentReceivers>
            Till:&nbsp;
            {measure.receiverRole.roleName}
          </ContentReceivers>
          <ContentText>Åtgärd: {measure.title}</ContentText>
          <MeasureContentWrapper>
            <MeasureText>{measure.text}</MeasureText>
            <MeasureCheckboxSection>
              <CheckboxWithLabel
                label={"Utfört"}
                checked={isCompleted}
                disabled={!measure.isCheckable}
                onChange={() => handleToggleCompleted()}
              />
            </MeasureCheckboxSection>
          </MeasureContentWrapper>
        </MeasureContentBox>
      </ContentBoxWrapper>
      <ContentSender $isSender={isSender}>
        <span>
          {user.name}, {user.roleName}
        </span>
        <span>{formatDateTime(timestamp)}</span>
      </ContentSender>
    </ContentRow>
  );
};

const MeasureContentBox = styled(ContentBox)`
  border-left: 8px solid ${({ theme }) => theme.card.cardHeader.backgroundColor};
`;

const MeasureText = styled.div`
  margin-top: 10px;
`;

const MeasureContentWrapper = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px solid #0d3050;
  margin-top: 10px;
  justify-content: space-between;
  gap: 6px;
`;

const MeasureCheckboxSection = styled.div`
  margin-bottom: 0px;

  > label {
    margin-top: 6px;
    color: #0d3050;

    > span::before {
      border-radius: 3px;
      background-color: #fff;
    }
  }
`;

import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  DangerButton,
  H2,
  SecondaryButton,
  SuccessButton,
} from "@collabodoc/component-library";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { device, size } from "../../Content/Style/devices";
import { USER_ROLES } from "../../enums/enums";
import { IssueClient, IssueModel } from "../../apiClient";

interface LeaveIssueButtonProps {
  issue: IssueModel;
  clinicRole: string;
}

export const LeaveIssueButton = ({
  issue,
  clinicRole,
}: LeaveIssueButtonProps) => {
  const { accessToken } = useOidcAccessToken();
  const [showModal, setShowModal] = useState(false);
  const isBetweenTabletAndLaptopS = useMediaQuery({
    query: `(min-width: ${size.tablet}) and (max-width: ${size.laptopS})`,
  });
  const isMobileM = useMediaQuery({ query: device.mobileM });
  const unansweredMeasures = issue.content.some((content) => {
    return (
      content.measure?.receiverRole.role === USER_ROLES[clinicRole] &&
      content.measure?.isCompleted === false
    );
  });

  const handleYesClick = () => {
    const client = new IssueClient({ accessToken });
    client.leaveIssue(issue.patientId, issue.issueId).then(() => {
      setShowModal(false);
    });
  };

  return (
    <>
      <DangerButton onClick={() => setShowModal(true)}>
        Lämna{!isBetweenTabletAndLaptopS && !isMobileM && " ärende"}
      </DangerButton>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <StyledH2>Lämna ärende</StyledH2>
        </Modal.Header>
        <Modal.Body>
          {unansweredMeasures ? (
            <TextWrapper>
              <Icon size={"4x"} icon={faTriangleExclamation} />
              Du har fortfarande obesvarade åtgärder på ärendet.
              <br />
              Är du säker på att du vill lämna ärendet?
            </TextWrapper>
          ) : (
            <p>Är du säker på att du vill lämna ärendet?</p>
          )}
        </Modal.Body>
        <ButtonDiv>
          <SecondaryButton onClick={() => setShowModal(false)}>
            Nej, avbryt
          </SecondaryButton>
          <SuccessButton onClick={handleYesClick}>Ja, lämna</SuccessButton>
        </ButtonDiv>
      </Modal>
    </>
  );
};

const StyledH2 = styled(H2)`
  margin: 0;
`;

const TextWrapper = styled.span`
  margin-left: 5px;
  display: flex;
  align-items: center;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.danger};
  margin-right: 10px;
`;

const ButtonDiv = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between;
`;

import { useEffect, useRef } from "react";
import styled from "styled-components";
import { size } from "../../../Content/Style/devices";
import {
  IssueContentType,
  IssueModel,
  UserViewModel,
} from "../../../apiClient";
import { Message } from "./Message";
import { Measure } from "./Measure";
import Event from "./Event";

const getIsSender = (onlyOneRoleInvolved: boolean, user: UserViewModel) =>
  onlyOneRoleInvolved ? user.isCurrentUser : user.isCurrentRole;

interface IssueContentProps {
  issue: IssueModel;
  onlyOneRoleInvolved: boolean;
  isIssueClosed: boolean;
}

const IssueContent = ({
  issue,
  onlyOneRoleInvolved,
  isIssueClosed = false,
}: IssueContentProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, [issue.content]);

  return (
    <IssueContentWrapper>
      {issue.content.map((c) => {
        switch (c.type) {
          case IssueContentType.Message:
            return (
              <Message
                key={c.contentId}
                message={c.message!}
                user={c.user}
                timestamp={c.timestamp}
                isSender={getIsSender(onlyOneRoleInvolved, c.user)}
                isIssueClosed={isIssueClosed}
              />
            );
          case IssueContentType.Measure:
            return (
              <Measure
                key={c.contentId}
                measure={c.measure!}
                user={c.user}
                timestamp={c.timestamp}
                isSender={getIsSender(onlyOneRoleInvolved, c.user)}
                isIssueClosed={isIssueClosed}
              />
            );
          case IssueContentType.MeasureCompleted:
            return (
              <Event
                key={c.contentId}
                text={`Åtgärden "${
                  c.measureCompletedEvent!.measureTitle
                }" markerades som utförd`}
                user={c.user}
                timestamp={c.timestamp}
                isSender={getIsSender(onlyOneRoleInvolved, c.user)}
                isIssueClosed={isIssueClosed}
              />
            );
          case IssueContentType.RoleLeftIssue:
            return (
              <Event
                key={c.contentId}
                text={`${c.user.roleName} lämnade ärendet`}
                user={c.user}
                timestamp={c.timestamp}
                isSender={getIsSender(onlyOneRoleInvolved, c.user)}
                isIssueClosed={isIssueClosed}
              />
            );
          case IssueContentType.IssueClosed:
            return (
              <Event
                key={c.contentId}
                text={`Ärendet avslutades`}
                timestamp={c.timestamp}
                user={c.user}
                isSender={getIsSender(onlyOneRoleInvolved, c.user)}
                isIssueClosed={isIssueClosed}
              />
            );
          case IssueContentType.Tagged:
            return (
              <Event
                key={c.contentId}
                text={`Ärendet taggades med "${c.tagText}"`}
                timestamp={c.timestamp}
                user={c.user}
                isSender={getIsSender(onlyOneRoleInvolved, c.user)}
                isIssueClosed={isIssueClosed}
              />
            );
          case IssueContentType.Untagged:
            return (
              <Event
                key={c.contentId}
                text={`Taggen "${c.tagText}" togs bort`}
                timestamp={c.timestamp}
                user={c.user}
                isSender={getIsSender(onlyOneRoleInvolved, c.user)}
                isIssueClosed={isIssueClosed}
              />
            );
          default:
            throw new Error(
              `Component for type ${c.type} not implemented yet!`,
            );
        }
      })}
      <div ref={ref}></div>
    </IssueContentWrapper>
  );
};

const IssueContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;

  @media (min-width: ${size.tablet}) {
    max-height: 1px;
  }
`;

export default IssueContent;

import {
  ContentBox,
  ContentBoxWrapper,
  ContentSender,
  ContentText,
} from "./ContentComponents";
import React from "react";
import styled from "styled-components";
import { size } from "../../../Content/Style/devices";
import { formatDateTime } from "../../../utils/dateUtils";
import { UserViewModel } from "../../../apiClient";

interface IssueEventProps {
  text: string;
  user: UserViewModel;
  timestamp: string;
  isSender: boolean;
  isIssueClosed: boolean;
}
const Event = ({
  text,
  user,
  timestamp,
  isSender,
  isIssueClosed,
}: IssueEventProps) => (
  <Row>
    <ContentBoxWrapper $isSender={isSender}>
      <ContentBox $isSender={isSender} $isClosed={isIssueClosed}>
        <EventContentText>{text}</EventContentText>
      </ContentBox>
    </ContentBoxWrapper>
    <EventContentSender $isSender={isSender}>
      <span>
        {user.name}, {user.roleName}
      </span>
      <span>{formatDateTime(timestamp)}</span>
    </EventContentSender>
  </Row>
);

const Row = styled.div`
  max-width: 75%;
  align-self: center;

  @media (max-width: ${size.tablet}) {
    max-width: unset;
    width: 100%;
  }
`;

const EventContentText = styled(ContentText)`
  font-size: small;
  justify-content: center;
`;

const EventContentSender = styled(ContentSender)`
  margin: 0;
  gap: 2rem;
`;

export default Event;

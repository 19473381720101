import { generatePath, useParams } from "react-router-dom";
import React, { useContext, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import {
  DropDown,
  H4,
  Input,
  InputLabel,
  PrimaryButton,
  ValidationError,
} from "@collabodoc/component-library";
import { ResidentDepartmentClient } from "../../apiClient";
import { SITE_URLS } from "../../enums/Urls";
import Breadcrumbs from "./Breadcrumbs";
import {
  Container,
  DropDownWrapper,
  Form,
  Li,
  Row,
  StyledLink,
  Ul,
} from "./Components";
import styled from "styled-components";

const AdminResidentDepartment = () => {
  const {
    residentDepartments,
    reFetchResidentDepartments,
    accessToken,
    careCenters,
    showCareCenter,
  } = useContext(GlobalContext);

  const [error, setError] = useState("");

  const { residentDepartmentId } = useParams<{
    residentDepartmentId: string;
  }>();

  if (!residentDepartmentId) {
    throw new Error("Invalid route params!");
  }

  const residentDepartmentClient = new ResidentDepartmentClient({
    accessToken,
  });

  const selectedResidentDepartment = residentDepartments.find(
    (r) => r.id === residentDepartmentId,
  );

  const [selectedDefaultCareCenterId, setSelectedDefaultCareCenterId] =
    useState<undefined | string>();

  const careCenterOptions = careCenters.map((u) => ({
    id: u.id,
    text: u.name,
  }));

  const selectedCareCenterName = careCenterOptions.find(
    ({ id }) => id === selectedDefaultCareCenterId,
  )?.text;
  const handleNewUnitSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    const data = Object.fromEntries(new FormData(form).entries());
    residentDepartmentClient
      .createUnit(residentDepartmentId, {
        unitName: data.unitName.toString(),
        defaultCareCenter: selectedDefaultCareCenterId,
      })
      .then(() => {
        form.reset();
        reFetchResidentDepartments();
        setSelectedDefaultCareCenterId(undefined);
      })
      .catch(() => setError("Kunde inte skapa enhet"));
  };

  return (
    <Container>
      <Breadcrumbs
        items={[
          ["Administration", SITE_URLS.ADMIN_VIEW],
          [selectedResidentDepartment?.name],
        ]}
      />
      <Row>
        <ValidationError>{error}</ValidationError>
      </Row>
      <Row>
        <InputLabel>Befintliga enheter</InputLabel>
      </Row>
      <Ul>
        {selectedResidentDepartment?.units.map((unit) => (
          <Li key={unit.unitId}>
            <StyledLink
              to={generatePath(SITE_URLS.ADMIN_UNIT_VIEW, {
                residentDepartmentId: residentDepartmentId,
                unitId: unit.unitId,
              })}
            >
              {unit.name}
            </StyledLink>
          </Li>
        ))}
      </Ul>
      <Form onSubmit={handleNewUnitSubmit}>
        <NewUnitWrapper>
          <H4>Skapa ny enhet</H4>
          <RowWrapper>
            <div>
              <InputLabel htmlFor={"newUnitName"}>Namn</InputLabel>

              <Input
                type={"text"}
                id={"newUnitName"}
                name={"unitName"}
                minLength={3}
                required
              />
            </div>
            <div></div>
            <div>
              <InputLabel>
                Vårdcentral för samtliga patienter på denna enhet
              </InputLabel>
              <DropDownWrapper>
                <DropDown
                  options={careCenterOptions}
                  selected={selectedCareCenterName}
                  handler={(id) => setSelectedDefaultCareCenterId(id as string)}
                />
              </DropDownWrapper>
            </div>
          </RowWrapper>
        </NewUnitWrapper>
        <ButtonWrapper>
          <PrimaryButton
            type={"submit"}
            disabled={!showCareCenter && !selectedDefaultCareCenterId}
          >
            Lägg till
          </PrimaryButton>
        </ButtonWrapper>
      </Form>
    </Container>
  );
};

export default AdminResidentDepartment;

const ButtonWrapper = styled.div`
  padding-top: 1rem;
`;

const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const NewUnitWrapper = styled.div`
  width: 50rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 0.5rem;
`;

import {
  DropDown,
  Input,
  InputLabel,
  ValidationError,
} from "@collabodoc/component-library";
import React, { useContext, useState } from "react";
import { ResidentDepartmentClient } from "../../apiClient";
import styled from "styled-components";
import { GlobalContext } from "../../context/GlobalContext";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "./Breadcrumbs";
import { SITE_URLS } from "../../enums/Urls";
import {
  Container,
  Form,
  NoWrapButton,
  NoWrapDangerButton,
  Row,
} from "./Components";

const AdminUnit = () => {
  const {
    residentDepartments,
    reFetchResidentDepartments,
    careCenters,
    accessToken,
    showCareCenter,
  } = useContext(GlobalContext);
  const { residentDepartmentId, unitId } = useParams();

  const navigate = useNavigate();

  if (!residentDepartmentId || !unitId) {
    throw new Error("Invalid route params!");
  }

  const selectedResidentDepartment = residentDepartments.find(
    (r) => r.id === residentDepartmentId,
  );

  const unit = selectedResidentDepartment?.units.find(
    (u) => u.unitId === unitId,
  );

  const [selectedCareCenterId, setSelectedCareCenterId] = useState<
    undefined | string
  >(unit?.defaultCareCenterId);
  const [newNameResult, setNewNameResult] = useState<{
    message?: string;
    error?: string;
  }>({});
  const [careCenterResult, setCareCenterResult] = useState<{
    message?: string;
    error?: string;
  }>({});

  const [removeUnitResult, setRemoveUnitResult] = useState<{
    message?: string;
    error?: string;
  }>({});

  if (!unitId) {
    return <span>ERROR</span>;
  }
  const residentDepartmentClient = new ResidentDepartmentClient({
    accessToken,
  });

  const careCenterOptions = careCenters.map((u) => ({
    id: u.id,
    text: u.name,
  }));

  const selectedCareCenterName = careCenterOptions.find(
    ({ id }) => id === selectedCareCenterId,
  )?.text;

  const handleEditUnitSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    const data = Object.fromEntries(new FormData(form).entries());
    residentDepartmentClient
      .editUnitName(residentDepartmentId, unitId, {
        unitName: data.unitName.toString(),
      })
      .then(() => {
        reFetchResidentDepartments();
        setNewNameResult({ message: "Namn ändrat" });
      })
      .catch(() =>
        setNewNameResult({ error: "Kunde inte ändra namn på enhet" }),
      );
  };
  const handleAddCareCenter = async () => {
    if (!selectedCareCenterId) {
      setCareCenterResult({ error: "Ingen vald vårdcentral!" });
      return;
    }
    residentDepartmentClient
      .addUnitCareCenter(residentDepartmentId, unitId, {
        careCenterId: selectedCareCenterId,
      })
      .then(() => {
        reFetchResidentDepartments();
        setCareCenterResult({ message: "Vårdcentral uppdaterad på enhet" });
      })
      .catch(() =>
        setCareCenterResult({
          error: "Kunde inte lägga till vårdcentral på enhet",
        }),
      );
  };
  const handleRemoveCareCenter = async () => {
    setSelectedCareCenterId(undefined);

    await residentDepartmentClient
      .removeUnitCareCenter(residentDepartmentId, unitId)
      .then(() => {
        reFetchResidentDepartments();
        setCareCenterResult({ message: "vårdcentral borttagen från enhet" });
      })
      .catch(() =>
        setCareCenterResult({
          error: "Kunde inte ta bort vårdcentral från enhet",
        }),
      );
  };

  const handleDeleteUnit = async () => {
    await residentDepartmentClient
      .deleteUnit(residentDepartmentId, unitId)
      .then(() => {
        reFetchResidentDepartments();
        navigate(
          {
            pathname: generatePath(SITE_URLS.ADMIN_RESIDENT_DEPARTMENT_VIEW, {
              residentDepartmentId: residentDepartmentId,
            }),
          },
          { replace: true },
        );
      })
      .catch(() =>
        setRemoveUnitResult({
          error: "Kunde inte ta bort avdelning",
        }),
      );
  };

  return (
    <Container>
      <Breadcrumbs
        items={[
          ["Administration", SITE_URLS.ADMIN_VIEW],
          [
            selectedResidentDepartment?.name,
            generatePath(SITE_URLS.ADMIN_RESIDENT_DEPARTMENT_VIEW, {
              residentDepartmentId: selectedResidentDepartment?.id,
            }),
          ],
          [unit?.name],
        ]}
      />
      <Form onSubmit={handleEditUnitSubmit}>
        <Row>
          <InputLabel htmlFor={"editUnitName"}>Nytt namn</InputLabel>
        </Row>
        <Row key={unitId}>
          <Input
            type={"text"}
            id={"editUnitName"}
            name={"unitName"}
            defaultValue={unit?.name}
            minLength={3}
            required
          />
          <NoWrapButton>Ändra</NoWrapButton>
          <NoWrapDangerButton
            type={"button"}
            disabled={unit?.hasPatients}
            onClick={() => handleDeleteUnit()}
          >
            Ta bort avdelning
          </NoWrapDangerButton>
        </Row>
        <Row>
          <ValidationError>{removeUnitResult.error}</ValidationError>
          {newNameResult.message}
          <ValidationError>{newNameResult.error}</ValidationError>
        </Row>
      </Form>
      <Row>
        <InputLabel>
          Vårdcentral för samtliga patienter på denna enhet
        </InputLabel>
      </Row>
      <Row>
        <DropDownWrapper>
          <DropDown
            options={careCenterOptions}
            selected={selectedCareCenterName}
            handler={(id) => setSelectedCareCenterId(id as string)}
          />
        </DropDownWrapper>
        <NoWrapButton type={"button"} onClick={() => handleRemoveCareCenter()}>
          Avregistrera vårdcentral
        </NoWrapButton>
        <NoWrapButton type={"button"} onClick={handleAddCareCenter}>
          Registrera vårdcentral
        </NoWrapButton>
      </Row>
      <Row>
        {careCenterResult.message}
        <ValidationError>{careCenterResult.error}</ValidationError>
      </Row>
    </Container>
  );
};
const DropDownWrapper = styled.div`
  width: 20rem;
  margin-right: 4rem;
`;

export default AdminUnit;

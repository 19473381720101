import { getRequestInit, useFetchy } from "@collabodoc/component-library";
import { useContext, useEffect } from "react";
import { GlobalContext } from "../context/GlobalContext";
import { API_URLS } from "../enums/Urls";
import { IssueModel } from "../apiClient";
import { hubConnection } from "../utils/hubConnection";

export const usePatientIssuesSubscription = (patientId: string) => {
  const { accessToken } = useContext(GlobalContext);
  const {
    data: issues,
    isLoading,
    doFetch,
  } = useFetchy<IssueModel[]>(
    API_URLS.PATIENT_ISSUES(patientId),
    getRequestInit({ accessToken }),
  );

  useEffect(() => {
    async function maybeReFetchPatient(
      _residentDepartmentId: string,
      _careCenterId: string,
      refreshedPatientId: string,
    ) {
      if (refreshedPatientId === patientId)
        await doFetch().finally(() =>
          console.debug("Patientlist refetch triggered by SignalR"),
        );
    }

    hubConnection.on("patientUpdated", maybeReFetchPatient);
    return () => {
      hubConnection.off("patientUpdated", maybeReFetchPatient);
    };
  }, [doFetch, patientId]);

  return {
    issues,
    isLoading,
  };
};

import {
  getRequestInit,
  LoadingView,
  useFetchy,
} from "@collabodoc/component-library";
import { nanoid } from "nanoid";
import { createContext, useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import { CardHeader, CardHeaderText } from "../../Content/Style/cardstyles";
import { device } from "../../Content/Style/devices";
import { GlobalContext } from "../../context/GlobalContext";
import { ACTIVE_TAB } from "../../enums/enums";
import { API_URLS } from "../../enums/Urls";
import { usePatientIssuesSubscription } from "../../hooks/usePatientIssuesSubscription";
import { Issue } from "./Issue";
import { ButtonRow } from "./ButtonRow";
import PatientIssues from "./PatientIssues";
import { PatientModel, RoleModel } from "../../apiClient";
import PatientInfoCard from "./PatientInfoCard";

export const IssueIdKey = "issueId";

const GlobalStyle = createGlobalStyle`
    body {
        background-color: #f1f1f1;
    }
`;

interface PatientViewContextType {
  patientId: string;
  patient: PatientModel;
  reFetchPatient: Function;
  residentDepartmentUserRoles: RoleModel[];
  selectedIssueId: number;
  setSelectedIssueId: (issueId: number) => void;
}

export const PatientViewContext = createContext<PatientViewContextType>(
  {} as PatientViewContextType,
);

const PatientView = () => {
  const { patientId } = useParams() as { patientId: string };
  const { residentDepartments, careCenters, accessToken } =
    useContext(GlobalContext);
  const location = useLocation();

  const { doFetch: reFetchPatient, data: patient } = useFetchy<PatientModel>(
    API_URLS.PATIENT(patientId),
    getRequestInit({ accessToken }),
  );

  const { issues } = usePatientIssuesSubscription(patientId!);
  const [newIssueKey, setNewIssueKey] = useState<string | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(ACTIVE_TAB.SELECTED_ISSUE_TAB);
  const isTablet = useMediaQuery({ query: device.tablet });
  const issueCount = issues?.length || 0;
  const [reminderModalOpen, setReminderModalOpen] = useState(
    location.state?.reminderModalOpen,
  );

  useEffect(() => {
    if (issues && issueCount === 0) setNewIssueKey(nanoid());
  }, [issueCount, issues]);

  useEffect(() => {
    setNewIssueKey(null);
  }, [patientId]);

  useEffect(() => {
    const urlIssueId = searchParams.get(IssueIdKey);
    if (!urlIssueId && issues && issues.length) {
      searchParams.set(IssueIdKey, issues?.[0]?.issueId.toString());
      setSearchParams(searchParams, { replace: true, state: location.state });
      setActiveTab(ACTIVE_TAB.SELECTED_ISSUE_TAB);
    }
  }, [issues, patient]);

  const setSelectedIssueId = (issueId: number) => {
    searchParams.set(IssueIdKey, issueId.toString());
    setSearchParams(searchParams, { replace: true });
    setNewIssueKey(null);
    setActiveTab(ACTIVE_TAB.SELECTED_ISSUE_TAB);
  };

  const handleNewIssueButtonClick = () => {
    setNewIssueKey(nanoid());
    searchParams.delete(IssueIdKey);
    setSearchParams(searchParams, { replace: true });
    setActiveTab(ACTIVE_TAB.SELECTED_ISSUE_TAB);
  };

  const selectedIssueId = Number(
    !newIssueKey && (searchParams.get(IssueIdKey) || issues?.[0]?.issueId),
  );

  const selectedIssue = issues?.find(
    (issue) => issue.issueId === selectedIssueId,
  );

  const patientDepartment = residentDepartments.find(
    (residentDepartment) =>
      residentDepartment.id === patient?.residentDepartmentId,
  );

  const patientCareCenter = careCenters.find(
    (careCenter) => careCenter.id === patient?.careCenterId,
  );

  const mergedList: RoleModel[] = [
    ...(patientDepartment?.userRoles || []),
    ...(patientCareCenter?.userRoles || []),
  ];

  const receiverRoles: RoleModel[] = mergedList.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (t) => t.role === value.role && t.roleName === value.roleName,
      ),
  );

  if (!patient || !issues) {
    return <LoadingView />;
  }

  return (
    <PatientViewContext.Provider
      value={{
        patientId: patientId!,
        patient,
        reFetchPatient,
        residentDepartmentUserRoles: receiverRoles || [],
        selectedIssueId,
        setSelectedIssueId,
      }}
    >
      <PatientViewWrapper>
        <GlobalStyle />
        <ButtonRow
          reminderModalOpen={reminderModalOpen}
          setReminderModalOpen={setReminderModalOpen}
        />
        {isTablet && (
          <>
            <PatientInfoCard />
            <IssueNavigationButtons>
              <StyledCardHeader
                onClick={() => setActiveTab(ACTIVE_TAB.ALL_ISSUES_TAB)}
                $isSelected={activeTab === ACTIVE_TAB.ALL_ISSUES_TAB}
              >
                <StyledCardHeaderText
                  $isSelected={activeTab === ACTIVE_TAB.ALL_ISSUES_TAB}
                >
                  Alla ärenden
                </StyledCardHeaderText>
              </StyledCardHeader>
              <StyledCardHeader
                onClick={() => setActiveTab(ACTIVE_TAB.SELECTED_ISSUE_TAB)}
                $isSelected={activeTab === ACTIVE_TAB.SELECTED_ISSUE_TAB}
              >
                <StyledCardHeaderText
                  $isSelected={activeTab === ACTIVE_TAB.SELECTED_ISSUE_TAB}
                >
                  {selectedIssue?.contactReason || "Nytt ärende"}
                </StyledCardHeaderText>
              </StyledCardHeader>
            </IssueNavigationButtons>
          </>
        )}

        <IssueWrapper>
          {(!isTablet || activeTab === ACTIVE_TAB.ALL_ISSUES_TAB) && (
            <LeftColWrapper>
              {!isTablet && <PatientInfoCard />}
              <PatientIssues
                issues={issues}
                handleNewIssueButtonClick={handleNewIssueButtonClick}
              />
            </LeftColWrapper>
          )}
          <Issue
            issue={selectedIssue}
            show={!isTablet || activeTab === ACTIVE_TAB.SELECTED_ISSUE_TAB}
            newIssueKey={newIssueKey}
            key={newIssueKey}
          />
        </IssueWrapper>
      </PatientViewWrapper>
    </PatientViewContext.Provider>
  );
};

const PatientViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1rem;

  @media ${device.desktop} {
    margin-left: 0;
    margin-right: 0;
    padding: 0.75rem;
  }
`;

const IssueNavigationButtons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  background-color: #f1f1f1;
  border-bottom: 1px solid #dee2e6;
`;

const StyledCardHeader = styled(CardHeader)<{ $isSelected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px 15px 0 0;
  padding: 12px 15px;
  flex: 1;
  text-align: center;
  background-color: ${({ $isSelected }) => !$isSelected && "#fff"};
  border: ${({ $isSelected, theme }) =>
    !$isSelected && `1px solid ${theme.colors.primary}`};
  border-bottom: ${({ $isSelected }) => !$isSelected && `none`};
`;

const StyledCardHeaderText = styled(CardHeaderText)<{ $isSelected: boolean }>`
  margin: 0;
  color: ${({ $isSelected, theme }) => !$isSelected && theme.colors.primary};

  @media ${device.tablet} {
    word-break: break-word;
    font-size: 14px;
  }
`;

const IssueWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  gap: 12px;

  @media ${device.tablet} {
    flex: 1;
    overflow-y: auto;
    flex-direction: column;
    gap: 0;
  }
`;

const LeftColWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
  min-width: 17rem;

  @media ${device.tablet} {
    flex: 1;
    overflow-y: auto;
    flex-direction: column;
    min-width: 10rem;
  }
`;

export default PatientView;

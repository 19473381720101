import { MutableRefObject, useEffect, useRef } from "react";

const useClickOutside = <T extends HTMLElement>(
  callback: (event: MouseEvent) => void,
): MutableRefObject<T | null> => {
  const myRef = useRef<T>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (myRef.current && !myRef.current.contains(event.target as Node)) {
        callback(event);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return (): void => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [myRef, callback]);

  return myRef;
};

export default useClickOutside;

import styled from "styled-components";
import { Link } from "react-router-dom";
import { Fragment } from "react";

type BreadcrumbItem = [text: string | undefined, url?: string | undefined];
const Breadcrumbs = ({ items = [] }: { items: BreadcrumbItem[] }) => (
  <Nav>
    {items.map(([text, url], index) => (
      <Fragment key={url}>
        {url ? <StyledLink to={url}>{text || ""}</StyledLink> : text}
        {index < items.length - 1 && " / "}
      </Fragment>
    ))}
  </Nav>
);

const Nav = styled.span`
  font-size: x-large;
  color: ${({ theme }) => theme.typography.linkColor};
`;
const StyledLink = styled(Link)`
  font-size: x-large;
  color: ${({ theme }) => theme.typography.linkColor};
`;
export default Breadcrumbs;

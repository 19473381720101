import { H3, InputLabel } from "@collabodoc/component-library";
import React, { useContext } from "react";
import styled from "styled-components";
import { device } from "../../Content/Style/devices";
import { GlobalContext } from "../../context/GlobalContext";
import { generatePath, Link } from "react-router-dom";
import { SITE_URLS } from "../../enums/Urls";
import Breadcrumbs from "./Breadcrumbs";
import { Container, Li, Row, StyledLink, Ul } from "./Components";

const AdminView = () => {
  const { residentDepartments } = useContext(GlobalContext);

  return (
    <Container>
      <Breadcrumbs items={[["Administration"]]} />
      <Row />
      <Row>
        <InputLabel>Befintliga boenden</InputLabel>
      </Row>
      <Ul>
        {residentDepartments.map((r) => (
          <Li key={r.id}>
            <StyledLink
              to={generatePath(SITE_URLS.ADMIN_RESIDENT_DEPARTMENT_VIEW, {
                residentDepartmentId: r.id,
              })}
            >
              {r.name}
            </StyledLink>
          </Li>
        ))}
      </Ul>
    </Container>
  );
};

export default AdminView;

const SITE_URLS = {
  PATIENT_LIST: "/patients",
  PATIENT_VIEW: "/patients/:patientId",
  ADMIN_VIEW: "/admin",
  ADMIN_RESIDENT_DEPARTMENT_VIEW:
    "/admin/residentDepartments/:residentDepartmentId",
  ADMIN_UNIT_VIEW:
    "/admin/residentDepartments/:residentDepartmentId/units/:unitId",
};

const API_URLS = {
  PATIENTLIST: `/api/patientlist`,
  RESIDENT_DEPARTMENTS: `/api/residentdepartments`,
  CARE_CENTERS: `/api/carecenters`,
  PATIENT: (patientId: string) => `/api/patients/${patientId}`,
  PATIENT_ISSUES: (patientId: string) => `/api/patients/${patientId}/issues`,
  PATIENT_ISSUE_MESSAGE: (patientId: string, issueId: number) =>
    `/api/patients/${patientId}/issues/${issueId}/message`,
  OPEN_FILE: (patientId: string, issueId: number, fileId: number) =>
    `/api/patients/${patientId}/issues/${issueId}/message/attachment/${fileId}`,
  MEASURE_TEMPLATES: `/api/measures/templates`,
  NOTIFICATIONS: `/api/notifications`,
  CONTACT_REASONS: `/api/contactreasons`,
  DEFAULT_RECEIVER_ROLE: `/api/defaultreceiver`,
  TAGS: `/api/tags`,
};

const EXTERNAL_URLS = {
  CAREGIVER_PORTAL_URL: `https://${window.env.careGiverPortalUrl}`,
};

export { SITE_URLS, API_URLS, EXTERNAL_URLS };

import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  doFetch,
  DropDown,
  getRequestInit,
  SecondaryButton,
  SuccessButton,
  ValidationError,
} from "@collabodoc/component-library";
import { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { GlobalContext } from "../../context/GlobalContext";
import { USER_ROLE_NAMES, USER_ROLES } from "../../enums/enums";
import MeasureSection from "./MeasureSection";
import { Footer, LeftButtonsWrapper, StyledH2 } from "./NoteModal";
import { PatientViewContext } from "./PatientView";
import { device } from "../../Content/Style/devices";
import { IssueModel, MeasuresClient, UserRoles } from "../../apiClient";
import { API_URLS } from "../../enums/Urls";

interface MeasureModalProps {
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  issue: IssueModel;
  selectedContactReason: { id: number; text: string };
}

export const MeasureModal = ({
  modalOpen,
  setModalOpen,
  issue,
  selectedContactReason,
}: MeasureModalProps) => {
  const { accessToken } = useOidcAccessToken();
  const { clinicRole, defaultReceiver } = useContext(GlobalContext);
  const { patientId, residentDepartmentUserRoles, setSelectedIssueId } =
    useContext(PatientViewContext);
  const selectableRoles = residentDepartmentUserRoles
    .filter(({ role }) => clinicRole && role !== USER_ROLES[clinicRole])
    .map(({ role, roleName }) => ({
      text: roleName,
      id: role,
    }));
  const [measures, setMeasures] = useState<
    { measureTemplateId: number; phrase: string | undefined }[]
  >([]);
  const [measureReceiver, setMeasureReceiver] = useState<UserRoles | undefined>(
    defaultReceiver,
  );
  const [errors, setErrors] = useState<string[]>([]);

  const handleAbort = () => {
    setModalOpen(false);
    setMeasureReceiver(undefined);
    setMeasures([]);
    setErrors([]);
  };

  const handleCreateIssueByMeasure = async () => {
    if (!measureReceiver) {
      setErrors(["Ärendet kunde inte skapas"]);
      return;
    }

    const data = new FormData();

    data.append("contactReason", selectedContactReason.id.toString());

    measures.forEach((measure, i) => {
      data.append(
        `measures[${i}][measureTemplateId]`,
        measure.measureTemplateId.toString(),
      );

      data.append(`measures[${i}][phrase]`, measure.phrase || "");

      data.append(`measures[${i}][receiverRoleId]`, measureReceiver.toString());
    });

    const url = API_URLS.PATIENT_ISSUES(patientId);
    const init = getRequestInit({ accessToken, method: "POST" });
    init.body = data;
    await doFetch<number>(url, init).then(({ response, data: issueId }) => {
      if (response?.ok) {
        setSelectedIssueId(issueId!);
        setErrors([]);
        setMeasures([]);
      } else {
        setErrors(["Ärendet kunde inte skapas"]);
      }
    });
  };

  const handleSendMeasure = async () => {
    if (!measureReceiver) {
      setErrors(["Åtgärden kunde inte skapas"]);
      return;
    }
    const measureData = measures.map((m) => ({
      measureTemplateId: m.measureTemplateId,
      phrase: m.phrase,
      receiverRoleId: measureReceiver,
    }));
    const client = new MeasuresClient({ accessToken });
    client
      .createMeasure(patientId, issue.issueId, measureData)
      .then(() => {
        setMeasures([]);
        setModalOpen(false);
        setMeasureReceiver(undefined);
      })
      .catch(() => setErrors(["Åtgärden kunde inte skapas"]));
  };

  return (
    <Modal
      size={"lg"}
      show={modalOpen}
      onHide={() => {
        setErrors([]);
        setModalOpen(false);
      }}
    >
      <Modal.Header>
        <StyledH2>Åtgärder</StyledH2>
      </Modal.Header>
      <Modal.Body>
        <MeasureSection measures={measures} setMeasures={setMeasures} />
        {errors.map((error, index) => (
          <MeasureValidationError key={index}>{error}</MeasureValidationError>
        ))}
      </Modal.Body>
      <Footer>
        <LeftButtonsWrapper>
          <SecondaryButton onClick={handleAbort}>Avbryt</SecondaryButton>
        </LeftButtonsWrapper>
        <RightButtonsWrapper>
          <DropDownWrapper>
            <DropDown
              placeholder={"Mottagare"}
              selected={measureReceiver && USER_ROLE_NAMES[measureReceiver]}
              options={selectableRoles}
              handler={(e) => setMeasureReceiver(e as number)}
              isDisabled={false}
            />
          </DropDownWrapper>

          {issue ? (
            <SuccessButton
              onClick={() => handleSendMeasure()}
              disabled={measures.length === 0 || !measureReceiver}
            >
              Skapa åtgärd
            </SuccessButton>
          ) : (
            <SuccessButton
              onClick={() => handleCreateIssueByMeasure()}
              disabled={
                measures.length === 0 ||
                !measureReceiver ||
                !selectedContactReason
              }
            >
              Skapa ärende
            </SuccessButton>
          )}
        </RightButtonsWrapper>
      </Footer>
    </Modal>
  );
};

const MeasureValidationError = styled(ValidationError)`
  font-size: 14px;
`;

export const RightButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  @media ${device.tablet} {
    flex-direction: column;
    align-items: unset;
    gap: 12px;

    button {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
    }
  }
`;

const DropDownWrapper = styled.div`
  > div {
    min-width: 170px;
  }
`;

import { DropDown } from "@collabodoc/component-library";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { IssueClient, IssueModel, IssueStatus } from "../../apiClient";
import {
  CardBody,
  CardComponent,
  CardFooter,
  CardHeader,
  CardHeaderText,
} from "../../Content/Style/cardstyles";
import { device } from "../../Content/Style/devices";
import { GlobalContext } from "../../context/GlobalContext";
import { CloseIssueButton } from "./CloseIssueButton";
import { LeaveIssueButton } from "./LeaveIssueButton";
import IssueContent from "./IssueContent/IssueContent";
import NewMessage from "./NewMessage";
import { PatientViewContext } from "./PatientView";
import { USER_ROLES } from "../../enums/enums";

const Issue = ({
  issue,
  show,
  newIssueKey,
}: {
  issue: IssueModel | undefined;
  show: boolean;
  newIssueKey: string | null;
}) => {
  const { selectableContactReasons, accessToken, clinicRole } =
    useContext(GlobalContext);
  const { patientId } = useContext(PatientViewContext);
  const isIssueOpen = issue?.status === IssueStatus.Open;
  const [selectedContactReason, setSelectedContactReason] = useState<{
    id: number;
    text: string;
  } | null>(null);
  const showFooter = isIssueOpen && issue.userHasBall && clinicRole;

  useEffect(() => {
    if (issue?.issueId && !issue.isReadByUser) {
      const client = new IssueClient({ accessToken });
      client.read(patientId, issue.issueId);
    }
  }, [issue?.isReadByUser, patientId, issue?.issueId, accessToken]);

  useEffect(() => {
    setSelectedContactReason(null);
  }, [issue]);

  const contactReasonsOptions = selectableContactReasons.map(
    (contactReason) => ({
      id: contactReason.id,
      text: contactReason.name,
    }),
  );
  const selectedContactReasonsHandler = (contactReasonId: number) => {
    const cr = contactReasonsOptions.find(
      (contactReason) => contactReason.id === contactReasonId,
    );

    cr && setSelectedContactReason(cr);
  };

  const onlyOneRoleInvolved = issue?.currentRoles.length === 1;
  const userCanParticipate =
    clinicRole &&
    (!onlyOneRoleInvolved ||
      (onlyOneRoleInvolved &&
        issue?.currentRoles.includes(USER_ROLES[clinicRole])));

  return (
    <IssueWrapper $show={show}>
      <IssueCardWrapper>
        <StyledIssueCard $showBorderRadius={showFooter}>
          <StyledCardHeader>
            <CardHeaderText>
              {issue?.contactReason ? issue.contactReason : "Nytt ärende"}
            </CardHeaderText>
          </StyledCardHeader>
          <IssueCardBody
            $selectedContactReason={selectedContactReason}
            $issue={issue}
          >
            {issue && (
              <IssueContent
                isIssueClosed={!isIssueOpen}
                issue={issue}
                onlyOneRoleInvolved={onlyOneRoleInvolved}
              />
            )}
            {newIssueKey && (
              <DropDownWrapper $selectedContactReason={selectedContactReason}>
                <DropDown
                  options={contactReasonsOptions}
                  selected={selectedContactReason?.text}
                  handler={(id) => selectedContactReasonsHandler(+id)}
                  isDisabled={false}
                  placeholder={
                    "Välj kontaktorsak. (Vid akuta ärenden, ring alltid)"
                  }
                />
              </DropDownWrapper>
            )}
          </IssueCardBody>
          {((newIssueKey && selectedContactReason) ||
            (!newIssueKey && userCanParticipate)) && (
            <NewMessage
              key={`newmessage-${issue?.issueId ?? 0}`}
              issue={issue}
              isIssueOpen={isIssueOpen}
              selectedContactReason={selectedContactReason}
              onlyOneRoleInvolved={onlyOneRoleInvolved}
            />
          )}
        </StyledIssueCard>
        {showFooter && (
          <Footer>
            {issue.userIsOnlyOneWithBall ? (
              <CloseIssueButton issue={issue} />
            ) : (
              <LeaveIssueButton issue={issue} clinicRole={clinicRole} />
            )}
          </Footer>
        )}
      </IssueCardWrapper>
    </IssueWrapper>
  );
};

export { Issue };

const IssueWrapper = styled.div<{ $show: boolean }>`
  display: ${({ $show }) => ($show ? "flex" : "none")};
  flex: 3;
  flex-direction: column;
`;

const IssueCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledCardHeader = styled(CardHeader)`
  @media ${device.tablet} {
    display: none;
  }
`;

const StyledIssueCard = styled(CardComponent)`
  flex: 1;
  margin-bottom: 0;
  border-radius: ${({ $showBorderRadius }) =>
    $showBorderRadius ? "0" : "0 0 10px 10px"};

  @media ${device.tablet} {
    padding: 0.75rem;
  }
`;

const DropDownWrapper = styled.div<{
  $selectedContactReason: { id: number; text: string } | null;
}>`
  display: flex;
  width: 100%;

  justify-content: ${({ $selectedContactReason }) =>
    !$selectedContactReason && "center"};

  > div {
    width: 100%;
  }

  > div > div {
    max-height: 40vh;

    @media ${device.tablet} {
      max-height: 30vh;
      position: relative;
      margin-top: 0;
      margin-bottom: 4px;
    }

    > div:nth-child(2) {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }
  }
`;

const IssueCardBody = styled(CardBody)`
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
  flex-direction: column;

  align-items: ${({ $selectedContactReason, $issue }) =>
    !$selectedContactReason && !$issue && "center"};
`;

const Footer = styled(CardFooter)`
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding: 0.75rem;
  padding-top: 0;
  border: none;
`;

import NurseIcon from "../../Content/Images/Icons/Sjukskoterska.svg";
import ClosedIcon from "../../Content/Images/Icons/lock_gray_svg.svg";
import DoctorIcon from "../../Content/Images/Icons/doctor_blue.svg";
import SecretaryIcon from "../../Content/Images/Icons/Sekreterare.svg";
import PhysiotherapistIcon from "../../Content/Images/Icons/Fysioterapeut.svg";
import PsychologistIcon from "../../Content/Images/Icons/Psykolog.svg";
import CounselorIcon from "../../Content/Images/Icons/Kurator.svg";
import OccupationalTherapistIcon from "../../Content/Images/Icons/Arbetsterapeut.svg";
import ExtraRoleIcon1 from "../../Content/Images/Icons/Number 1.svg";
import ExtraRoleIcon2 from "../../Content/Images/Icons/Number 2.svg";
import ExtraRoleIcon3 from "../../Content/Images/Icons/Number 3.svg";
import ExtraRoleIcon4 from "../../Content/Images/Icons/Number 4.svg";
import ExtraRoleIcon5 from "../../Content/Images/Icons/Number 5.svg";
import ExtraRoleIcon6 from "../../Content/Images/Icons/Number 6.svg";
import ExtraRoleIcon7 from "../../Content/Images/Icons/Number 7.svg";
import ExtraRoleIcon8 from "../../Content/Images/Icons/Number 8.svg";
import ExtraRoleIcon9 from "../../Content/Images/Icons/Number 9.svg";
import { USER_ROLES } from "../../enums/enums";
import styled from "styled-components";
import React from "react";

const getStatusIcon = (
  currentRole: number | undefined,
  extraRole: number | undefined,
): { icon: string; tooltipText: string } => {
  if (currentRole === undefined && !extraRole) {
    return { icon: ClosedIcon, tooltipText: "Avslutat" };
  }

  let icon: string = "";
  let tooltipText: string = "";

  switch (currentRole) {
    case USER_ROLES.NurseUser:
      icon = NurseIcon;
      tooltipText = "Sjuksköterska";
      break;
    case USER_ROLES.DoctorUser:
      icon = DoctorIcon;
      tooltipText = "Läkare";
      break;
    case USER_ROLES.SecretaryUser:
      icon = SecretaryIcon;
      tooltipText = "Sekreterare";
      break;
    case USER_ROLES.PhysiotherapistUser:
      icon = PhysiotherapistIcon;
      tooltipText = "Fysioterapeut";
      break;
    case USER_ROLES.PsychologistUser:
      icon = PsychologistIcon;
      tooltipText = "Psykolog";
      break;
    case USER_ROLES.CounselorUser:
      icon = CounselorIcon;
      tooltipText = "Kurator";
      break;
    case USER_ROLES.OccupationalTherapistUser:
      icon = OccupationalTherapistIcon;
      tooltipText = "Arbetsterapeut";
      break;
    default:
      icon = "";
      tooltipText = "";
      break;
  }

  if (extraRole !== undefined) {
    switch (extraRole) {
      case 1:
        icon = ExtraRoleIcon1;
        tooltipText = "+1";
        break;
      case 2:
        icon = ExtraRoleIcon2;
        tooltipText = "+2";
        break;
      case 3:
        icon = ExtraRoleIcon3;
        tooltipText = "+3";
        break;
      case 4:
        icon = ExtraRoleIcon4;
        tooltipText = "+4";
        break;
      case 5:
        icon = ExtraRoleIcon5;
        tooltipText = "+5";
        break;
      case 6:
        icon = ExtraRoleIcon6;
        tooltipText = "+6";
        break;
      case 7:
        icon = ExtraRoleIcon7;
        tooltipText = "+7";
        break;
      case 8:
        icon = ExtraRoleIcon8;
        tooltipText = "+8";
        break;
      case 9:
        icon = ExtraRoleIcon9;
        tooltipText = "+9";
        break;
      default:
        icon = "";
        tooltipText = "";
        break;
    }
  }

  return { icon, tooltipText };
};

const Image = styled.img`
  margin: 2px;
  height: 25px;
`;

interface StatusIconProps {
  currentRole?: number;
  extraRole?: number;
  alt?: string;
}

export const StatusIcon = ({
  currentRole,
  extraRole,
  alt,
}: StatusIconProps): React.ReactElement => {
  const { icon, tooltipText } = getStatusIcon(currentRole, extraRole);
  return <Image alt={alt} src={icon} title={tooltipText} />;
};

import {
  CheckboxWithLabel,
  H2,
  PrimaryButton,
  SecondaryButton,
  TextAreaWithLabelAndError,
} from "@collabodoc/component-library";
import { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import {
  CardBody,
  CardComponent,
  CardHeader,
  CardHeaderText,
} from "../Content/Style/cardstyles";
import { GlobalContext } from "../context/GlobalContext";
import { NotificationClient } from "../apiClient";
import { device } from "../Content/Style/devices";

interface CreateNotificationProps {
  show: boolean;
  setShow: (value: boolean) => void;
}

const CreateNotification = ({ show, setShow }: CreateNotificationProps) => {
  const [message, setMessage] = useState("");
  const [selectedResidentDepartmentIds, setSelectedResidentDepartmentIds] =
    useState<string[]>([]);
  const { residentDepartments, accessToken } = useContext(GlobalContext);
  const client = new NotificationClient({ accessToken });

  const selectableResidentDepartments = residentDepartments.filter(
    (r) => r.hasPatients,
  );
  const allResidentDepartmentIdsSelected =
    selectedResidentDepartmentIds.length ===
    selectableResidentDepartments.length;

  const handleReceiverChange = (residentDepartmentId: string) => {
    setSelectedResidentDepartmentIds((prevState) =>
      prevState.includes(residentDepartmentId)
        ? selectedResidentDepartmentIds.filter(
            (r) => r !== residentDepartmentId,
          )
        : [...prevState, residentDepartmentId],
    );
  };

  const handleAllReceiversChange = () => {
    allResidentDepartmentIdsSelected
      ? setSelectedResidentDepartmentIds([])
      : setSelectedResidentDepartmentIds(
          selectableResidentDepartments.map(
            (residentDepartment) => residentDepartment.id,
          ),
        );
  };

  const handleFormSubmit = () => {
    if (message !== "" && selectedResidentDepartmentIds.length > 0) {
      client
        .postNotifications({
          message,
          residentDepartmentIds: selectedResidentDepartmentIds,
        })
        .then(() => {
          setMessage("");
          setSelectedResidentDepartmentIds([]);
          setShow(false);
        });
    }
  };

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header>
        <StyledH2>Skapa notis</StyledH2>
      </Modal.Header>
      <ModalBody>
        <StyledCardComponent>
          <CardHeader>
            <CardHeaderText>Välj mottagare</CardHeaderText>
          </CardHeader>
          <StyledCardBody>
            <CheckboxWithLabel
              label={"Välj alla"}
              checked={allResidentDepartmentIdsSelected}
              onChange={() => handleAllReceiversChange()}
            />
            {selectableResidentDepartments.map((rd) => (
              <CheckboxWithLabel
                key={rd.id}
                label={rd.name}
                checked={selectedResidentDepartmentIds.includes(rd.id)}
                onChange={() => handleReceiverChange(rd.id)}
              />
            ))}
          </StyledCardBody>
        </StyledCardComponent>
        <MessageWrapper>
          <TextAreaWithLabelAndError
            maxLength={300}
            label={"Meddelande"}
            minRows={4}
            value={message}
            handleChange={(e) => setMessage(e)}
            resizeHandles={false}
          />
        </MessageWrapper>
      </ModalBody>
      <Footer>
        <SecondaryButton onClick={() => setShow(false)}>Avbryt</SecondaryButton>
        <PrimaryButton
          disabled={message === "" || selectedResidentDepartmentIds.length < 1}
          onClick={() => handleFormSubmit()}
        >
          Skapa
        </PrimaryButton>
      </Footer>
    </Modal>
  );
};

const StyledH2 = styled(H2)`
  margin: 0;
`;

const ModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledCardComponent = styled(CardComponent)`
  flex: 1;
  margin: 0;
`;

const StyledCardBody = styled(CardBody)`
  display: flex;
  flex-direction: column;
  border: 1px solid #000;
  border-radius: 0 0 10px 10px;
  padding: 6px 12px;
  max-height: 400px;
  overflow-y: auto;

  > label {
    margin: 0;
  }
`;

const MessageWrapper = styled.div`
  flex: 1;

  > textarea {
    border-radius: 4px;
  }

  > label {
    font-weight: normal;
    margin: 0;
  }
`;

const Footer = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between;

  @media ${device.tablet} {
    flex-direction: column-reverse;
    align-items: unset;

    button {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
    }
  }
`;

export default CreateNotification;

import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  doFetch,
  fileUploadValidation,
  getRequestInit,
  LoadingView,
  PrimaryButton,
  TextAreaWithLabelAndError,
  TextButton,
  useFetchy,
  ValidationError,
} from "@collabodoc/component-library";
import {
  faListCheck,
  faPaperclip,
  faPaperPlane,
  faTag,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { CardBody } from "../../Content/Style/cardstyles";
import { device } from "../../Content/Style/devices";
import { GlobalContext } from "../../context/GlobalContext";
import { API_URLS } from "../../enums/Urls";
import { MeasureModal } from "./MeasureModal";
import { PatientViewContext } from "./PatientView";
import { RecipientModal } from "./RecipientModal";
import RecipientSelect from "./RecipientSelect";
import TagModal from "./TagModal";

const FILE_VALIDATION_SETTINGS = {
  acceptedFileTypes: [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "application/pdf",
  ],
  maxMBFileSize: 5,
  maxFileNameLength: 100,
};

const NewMessage = ({
  issue,
  isIssueOpen,
  selectedContactReason,
  onlyOneRoleInvolved,
}) => {
  const { patientId } = useParams();
  const {
    measureTemplates = [],
    clinicRole,
    defaultReceiver,
  } = useContext(GlobalContext);
  const { setSelectedIssueId } = useContext(PatientViewContext);
  const { accessToken } = useOidcAccessToken();

  const fileInputRef = useRef();
  const [messageText, setMessageText] = useState("");
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState([]);
  const [measureModalOpen, setMeasureModalOpen] = useState(false);
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [receiverRoleIds, setReceiverRoleIds] = useState([]);

  const { doFetch: sendMessage, isLoading } = useFetchy();
  const isLaptopM = useMediaQuery({ query: device.laptopM });
  const isTablet = useMediaQuery({ query: device.tablet });
  const isMobileL = useMediaQuery({ query: device.mobileL });

  const handleSendMessage = async () => {
    const data = new FormData();

    data.append("text", messageText);

    files.forEach((file) => {
      data.append("attachments", file);
    });

    for (let value of receiverRoleIds) {
      data.append("receiverRoleIds[]", value);
    }

    if (messageText) {
      const url = API_URLS.PATIENT_ISSUE_MESSAGE(
        issue && issue.patientId,
        issue && issue.issueId,
      );
      const init = getRequestInit({ accessToken, method: "POST" });
      init.body = data;
      await sendMessage(url, init).then(({ response }) => {
        if (response.ok) {
          setMessageText("");
          setFiles([]);
          setErrors([]);
          if (issue.currentRoles.length > 2 || receiverRoleIds.length > 1) {
            setReceiverRoleIds([]);
          }
        } else {
          setErrors(["Meddelandet kunde inte skickas"]);
        }
      });
    }
  };

  const handleCreateIssueButtonClick = async () => {
    const data = new FormData();
    data.append("contactReason", selectedContactReason.id);

    data.append("messageRequest[text]", messageText);

    files.forEach((file) => {
      data.append(`messageRequest.attachments`, file);
    });

    for (let value of receiverRoleIds) {
      data.append("messageRequest.receiverRoleIds[]", value);
    }

    const url = API_URLS.PATIENT_ISSUES(patientId);
    const init = getRequestInit({ accessToken, method: "POST" });
    init.body = data;
    await doFetch(url, init).then(({ response, data: issueId }) => {
      if (response.ok) {
        setSelectedIssueId(issueId);
        setMessageText("");
        setFiles([]);
        setErrors([]);
        setReceiverRoleIds([]);
      } else {
        setErrors(["Ärendet kunde inte skapas"]);
      }
    });
  };

  const handleFileChange = ({ target }) => {
    const [allFiles, errors] = fileUploadValidation(
      target.files,
      files,
      FILE_VALIDATION_SETTINGS,
    );
    setErrors(errors);
    setFiles(allFiles);
    !messageText && setMessageText("Vänligen se bifogad fil");
  };

  const handleDeleteFile = (index) => {
    files.splice(index, 1);
    setFiles([...files]);
    fileInputRef.current.value = null; // Needed so that onchange event will be triggered if file is uploaded again
  };

  const handleChangeReceiverRoles = (e) => {
    const role = Number(e);
    setReceiverRoleIds((prevState) =>
      prevState.includes(role)
        ? receiverRoleIds.filter((r) => r !== role)
        : [...prevState, role],
    );
  };

  const [recipientsModalOpen, setRecipientsModalOpen] = useState(false);

  const handleSelectAllRecipients = (roles, allOptionsSelected) => {
    if (allOptionsSelected) {
      setReceiverRoleIds([]);
    } else {
      setReceiverRoleIds(roles.map((ro) => ro.id));
    }
  };

  return (
    <>
      {isLoading && <LoadingView />}
      <MessageCardBody $loading={isLoading}>
        {clinicRole && (isIssueOpen || !issue) && (
          <NewMessageWrapper>
            <FileList>
              {files.map((file, index) => (
                <li key={index}>
                  <RemoveFileButton onClick={() => handleDeleteFile(index)}>
                    <FontAwesomeIcon icon={faXmark} />
                  </RemoveFileButton>
                  {file.name}
                </li>
              ))}
            </FileList>
            {errors.map((error, index) => (
              <FileValidationError key={index}>{error}</FileValidationError>
            ))}
            <TextSection>
              <TextAreaWithLabelAndError
                disabled={isLoading}
                maxRows={3}
                minRows={3}
                handleChange={(e) => setMessageText(e)}
                value={messageText}
                resizeHandles={false}
              />

              <TextAreaFooter>
                <RecipientSelect
                  receiverRoleIds={receiverRoleIds}
                  handleChangeReceiverRoles={handleChangeReceiverRoles}
                  currentRoles={issue?.currentRoles}
                  clinicRole={clinicRole}
                  setRecipientsModalOpen={setRecipientsModalOpen}
                  handleSelectAllRecipients={handleSelectAllRecipients}
                  setReceiverRoleIds={setReceiverRoleIds}
                  defaultReceiver={defaultReceiver}
                  isNewIssue={!issue}
                />
                {(isIssueOpen || !issue) && (
                  <TextAreaFooterRight>
                    <MultipleButtonWrapper>
                      {measureTemplates.length > 0 && !onlyOneRoleInvolved && (
                        <MultipleButton
                          onClick={() => setMeasureModalOpen(true)}
                        >
                          <FontAwesomeIcon icon={faListCheck} />
                          {!isLaptopM && "Åtgärder"}
                        </MultipleButton>
                      )}
                      <ButtonSeparator
                        show={
                          measureTemplates.length > 0 && !onlyOneRoleInvolved
                        }
                      >
                        <br />
                      </ButtonSeparator>
                      <MultipleButton
                        onClick={() => fileInputRef.current.click()}
                      >
                        <FontAwesomeIcon icon={faPaperclip} />
                        {!isLaptopM && "Bifoga"}
                        <input
                          hidden
                          ref={fileInputRef}
                          onChange={handleFileChange}
                          type="file"
                          multiple
                          accept={FILE_VALIDATION_SETTINGS.acceptedFileTypes.join(
                            ", ",
                          )}
                        />
                      </MultipleButton>
                      <ButtonSeparator show={issue}>
                        <br />
                      </ButtonSeparator>
                      <MultipleButton
                        hidden={!issue}
                        onClick={() => setTagModalOpen(true)}
                      >
                        <TagIcon icon={faTag} />
                        {!isLaptopM && "Taggar"}
                      </MultipleButton>
                    </MultipleButtonWrapper>
                    {isIssueOpen && (
                      <StyledPrimaryButton
                        disabled={
                          !messageText ||
                          receiverRoleIds.length === 0 ||
                          isLoading
                        }
                        onClick={handleSendMessage}
                      >
                        <FontAwesomeIcon icon={faPaperPlane} />
                        {!isLaptopM && "Skicka"}
                        <br />
                      </StyledPrimaryButton>
                    )}
                    {!issue && (
                      <StyledPrimaryButton
                        disabled={
                          !selectedContactReason ||
                          !messageText ||
                          receiverRoleIds.length === 0
                        }
                        onClick={handleCreateIssueButtonClick}
                      >
                        Skapa {!isMobileL && " ärende"}
                      </StyledPrimaryButton>
                    )}
                  </TextAreaFooterRight>
                )}
              </TextAreaFooter>
            </TextSection>
          </NewMessageWrapper>
        )}
      </MessageCardBody>
      <MeasureModal
        issue={issue}
        modalOpen={measureModalOpen}
        setModalOpen={setMeasureModalOpen}
        selectedContactReason={selectedContactReason}
      />
      <TagModal
        issue={issue}
        modalOpen={tagModalOpen}
        setModalOpen={setTagModalOpen}
      />
      <RecipientModal
        receiverRoles={receiverRoleIds}
        setReceiverRoles={handleChangeReceiverRoles}
        modalOpen={recipientsModalOpen}
        setModalOpen={setRecipientsModalOpen}
        handleSelectAllRecipients={handleSelectAllRecipients}
        clinicRole={clinicRole}
      />
    </>
  );
};

const TextAreaFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  border: 1px solid black;
  border-top: none;
  border-radius: 0 0 4px 4px;
  padding: 12px;
  width: 100%;
  background: white;

  @media ${device.laptopM} {
    gap: 12px;
  }

  @media ${device.mobileL} {
    gap: 4px;
  }
`;

const TextAreaFooterRight = styled.span`
  display: flex;
  align-items: flex-end;
  gap: 16px;

  @media ${device.laptopM} {
    gap: 12px;
  }

  @media ${device.mobileL} {
    gap: 4px;
  }
`;

const MultipleButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 8px;
  padding: 6px 8px;
`;

const MultipleButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border: none;
  background-color: #fff;
  padding: 0;
  color: ${({ theme }) => theme.colors.primary};

  :hover {
    text-decoration: underline;
  }

  > svg {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const ButtonSeparator = styled.div`
  border-right: ${({ show }) => (!show ? "none" : "1px solid black")};
  margin: ${({ show }) => (!show ? "0" : "0 6px")};
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  white-space: nowrap;

  @media ${device.laptopM} {
    gap: 0;
  }
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;

  > textarea {
    border-radius: 4px 4px 0 0;
    padding: 12px 12px 0 12px;
    border-bottom: none;
    border-color: #000;
  }
`;

const NewMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
`;

const FileList = styled.ul`
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;

  li {
    display: inline -block;
    padding-right: 15px;
    font-size: 14px;
    list-style: none;
  }
`;

const RemoveFileButton = styled(TextButton)`
  padding-right: 4px;
  padding-left: 0px;

  &:hover {
    color: darkred;
  }
`;

const FileValidationError = styled(ValidationError)`
  font-size: 14px;
`;

const MessageCardBody = styled(CardBody)`
  display: flex;
  flex-grow: 0;
  flex-direction: column;
  opacity: ${(props) => (props.$loading ? "0.5" : "1")};
`;

const TagIcon = styled(FontAwesomeIcon)`
  transform: rotate(90deg);
`;

export default NewMessage;

import {
  SearchBarWithLabel,
  SecondaryButton,
} from "@collabodoc/component-library";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { device } from "../../Content/Style/devices";
import usePatientListFilters from "../../hooks/usePatientListFilters";
import { MultiFilter } from "./MultiFilter";

interface SideMenuProps {
  handleSearchInput: (value: string) => void;
  setShowSideMenu: (value: boolean) => void;
  showSideMenu: boolean;
}

export const SideMenu = ({
  handleSearchInput,
  setShowSideMenu,
  showSideMenu,
}: SideMenuProps) => {
  const [transitionEnded, setTransitionEnded] = useState(false);
  const { filters } = usePatientListFilters();
  const isLaptopM = useMediaQuery({ query: device.laptopM });
  const ref = useRef<HTMLInputElement>(null);
  return (
    <FilterContainer
      $showSideMenu={showSideMenu}
      $transitionEnded={transitionEnded}
      onTransitionEnd={() => setTransitionEnded(!transitionEnded)}
    >
      {isLaptopM && (
        <ButtonWrapper>
          <SecondaryButton onClick={() => setShowSideMenu(false)}>
            <FontAwesomeIcon icon={faClose} />
            Stäng filtrering
          </SecondaryButton>
        </ButtonWrapper>
      )}
      <SearchBarWrapper>
        <SearchBarWithLabel
          ref={ref}
          handleChange={handleSearchInput}
          placeholder={"Sök bland filtrerade patienter"}
        />
      </SearchBarWrapper>
      {showSideMenu &&
        filters.map((options, index) => (
          <MultiFilter
            key={index}
            options={options}
            showAllOption={options.length > 3}
            showFilterDefault={options.length <= 3}
          />
        ))}
    </FilterContainer>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.75rem;

  > button {
    width: 100%;
  }

  > button:first-of-type {
    > svg {
      margin-right: 4px;
    }
  }
`;

const SearchBarWrapper = styled.div`
  padding: 0.75rem;

  @media ${device.laptopM} {
    padding-top: 0;
  }
`;

const FilterContainer = styled.div<{
  $showSideMenu: boolean;
  $transitionEnded: boolean;
}>`
  width: ${(props) => (props.$showSideMenu ? "20%" : "0%")};
  background-color: white;
  border-right: 1px solid #e1e1e1;
  overflow: hidden;
  transition: ${(props) => (props.$showSideMenu ? "width 0.5s" : "none")};
  overflow-y: auto;
  @media ${device.laptopM} {
    width: ${(props) => (props.$showSideMenu ? "100%" : "0%")};
  }

  > div {
    > label {
      white-space: ${(props) =>
        props.$transitionEnded ? "normal" : "nowrap;"};
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

`;

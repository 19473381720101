import { OidcConfiguration } from "@axa-fr/oidc-client/src/types";

const oidcConfiguration: OidcConfiguration = {
  authority: window.env.authority,
  client_id: window.env.clientId,
  monitor_session: true,
  redirect_uri: `${window.location.origin}/authentication/callback`,
  silent_redirect_uri: `${window.location.origin}/authentication/silent-callback`,
  scope: "openid profile role carecenter medicalround_api offline_access",
};

export default oidcConfiguration;

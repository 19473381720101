import { FILTER_KEYS } from "../enums/enums";
import usePatientListFilters from "./usePatientListFilters";

interface Pagination<T> {
  page: number;
  pageCount: number;
  pageItems: T[];
  resetPagination: Function;
  itemsPerPage: number;
}

const usePagination = <T,>(
  items: T[] = [],
  itemsPerPage: number = 20
): Pagination<T> => {
  const { getFilterValue, clearFilter } = usePatientListFilters();
  const page = Math.max(Number(getFilterValue(FILTER_KEYS.PAGE)) || 1, 1);
  itemsPerPage = Number(getFilterValue("show")) || itemsPerPage;
  const pageCount = Math.ceil(items.length / itemsPerPage);
  const pageItems = items.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  const resetPagination = (): void => clearFilter(FILTER_KEYS.PAGE);

  return {
    page,
    pageCount,
    pageItems,
    resetPagination,
    itemsPerPage,
  };
};

export default usePagination;

import { IssueModel, TagClient } from "../../apiClient";
import { Modal } from "react-bootstrap";
import { Footer, LeftButtonsWrapper, StyledH2 } from "./NoteModal";
import {
  InputLabel,
  PrimaryButton,
  SecondaryButton,
  TextInputWithLabelAndError,
} from "@collabodoc/component-library";
import { useContext, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { Tag } from "../PatientListView/CollapsableRow";
import styled from "styled-components";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { PatientViewContext } from "./PatientView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faXmark } from "@fortawesome/free-solid-svg-icons";

interface TagModalProps {
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  issue: IssueModel;
}

const TagModal = ({ modalOpen, setModalOpen, issue }: TagModalProps) => {
  const [newTag, setNewTag] = useState("");
  const [createTagSectionCollapsed, setCreateTagSectionCollapsed] =
    useState(true);
  const { reFetchPatient, patientId } = useContext(PatientViewContext);
  const { tags, reFetchTags } = useContext(GlobalContext);
  const { accessToken } = useOidcAccessToken();
  const client = new TagClient({ accessToken });

  const issueTags =
    tags &&
    issue &&
    tags.filter((x) => issue.tags.map((t) => t.name).includes(x.name));
  const selectableTags =
    tags &&
    issue &&
    tags.filter((x) => !issue.tags.map((t) => t.name).includes(x.name));

  const handleCloseModal = () => {
    reFetchTags();
    setNewTag("");
    setCreateTagSectionCollapsed(true);
    setModalOpen(false);
  };

  const handleCreateTag = () => {
    client
      .createTag({
        tagName: newTag,
        issueId: issue.issueId,
        patientId: patientId,
      })
      .then(() => {
        reFetchPatient();
        reFetchTags();
        handleCloseModal();
      });
  };

  const handleRemoveTag = (tagName: string) => {
    const issueTagId = issue.tags.find((i) => i.name == tagName)!.id;
    client.removeTag(patientId, issueTagId).then(() => {
      reFetchTags();
      reFetchPatient();
      handleCloseModal();
    });
  };

  const handleAddTag = (tagId: number) => {
    const issueId = issue.issueId;
    client.tagIssue(tagId, { issueId, patientId }).then(() => {
      reFetchPatient();
      handleCloseModal();
    });
  };

  return (
    <Modal show={modalOpen} onHide={() => handleCloseModal()}>
      <Modal.Header>
        <StyledH2>Tagga ärende</StyledH2>
      </Modal.Header>
      <Modal.Body>
        <TagGroupLabel>Ärendets taggar</TagGroupLabel>
        <Tags>
          {issueTags && issueTags.length ? (
            issueTags.map((tag) => {
              return (
                <IssueTagsWrapper
                  key={tag.tagid}
                  onClick={() => handleRemoveTag(tag.name)}
                >
                  <Tag>
                    {tag.name}
                    <FontAwesomeIcon icon={faXmark} />
                  </Tag>
                </IssueTagsWrapper>
              );
            })
          ) : (
            <i>Inga taggar på ärendet</i>
          )}
        </Tags>
        <hr />
        <TagGroupLabel>Välj tagg att lägga till</TagGroupLabel>
        <Tags>
          {selectableTags && selectableTags.length ? (
            selectableTags.map((tag) => {
              return (
                <TagToAddWrapper
                  key={tag.tagid}
                  onClick={() => handleAddTag(tag.tagid)}
                >
                  <Tag>{tag.name}</Tag>
                </TagToAddWrapper>
              );
            })
          ) : (
            <i>Inga taggar att lägga till. Lägg till taggar nedan.</i>
          )}
        </Tags>
        <hr />
        <span
          onClick={() =>
            setCreateTagSectionCollapsed(!createTagSectionCollapsed)
          }
        >
          <CreateTagLabel>
            Skapa ny tagg
            <FontAwesomeIcon icon={faChevronDown} />
          </CreateTagLabel>
        </span>
        <CreateTagSection $isCollapsed={createTagSectionCollapsed}>
          <TextInputWithLabelAndError
            maxLength={30}
            handleChange={(e) => setNewTag(e)}
            label={""}
          />
          <CreateTagBtnSection>
            <PrimaryButton onClick={() => handleCreateTag()}>
              Lägg till
            </PrimaryButton>
          </CreateTagBtnSection>
        </CreateTagSection>
      </Modal.Body>
      <Footer>
        <LeftButtonsWrapper>
          <SecondaryButton onClick={() => handleCloseModal()}>
            Avbryt
          </SecondaryButton>
        </LeftButtonsWrapper>
      </Footer>
    </Modal>
  );
};

const Tags = styled.div`
  display: flex;
  gap: 2px;
  flex-flow: wrap;
  overflow: hidden;
`;

const CreateTagBtnSection = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
`;

const CreateTagSection = styled.div<{
  $isCollapsed: boolean;
}>`
  display: ${({ $isCollapsed }) => ($isCollapsed ? "none" : "block")};
`;

const CreateTagLabel = styled(InputLabel)`
  cursor: pointer;
  margin-bottom: 2px;

  > svg {
    margin-left: 5px;
  }
`;

const TagGroupLabel = styled.label`
  font-weight: bold;
  margin-bottom: 1px;
`;

const IssueTagsWrapper = styled.div`
  > div {
    display: flex;
    align-items: center;
    gap: 2px;
    cursor: pointer;
    margin-top: 1px;
  }
`;

const TagToAddWrapper = styled(IssueTagsWrapper)`
  > div {
    background-color: white;
    color: #757575;
    border-color: #757575;
  }
`;

export default TagModal;

import PatientListRedirect from "./components/PatientListView/PatientListRedirect";
import { SITE_URLS } from "./enums/Urls";
import PatientListView from "./components/PatientListView/PatientListView";
import PatientView from "./components/PatientView/PatientView";
import ProtectedRoute from "./ProtectedRoute";
import AdminView from "./components/AdminView/AdminView";
import Root from "./Root";
import { UnexpectedErrorView } from "@collabodoc/component-library";
import AdminResidentDepartment from "./components/AdminView/AdminResidentDepartment";
import AdminUnit from "./components/AdminView/AdminUnit";

export const routes = [
  {
    path: "/",
    element: <Root />,
    errorElement: <UnexpectedErrorView />,
    children: [
      {
        index: true,
        element: <PatientListRedirect />,
      },
      {
        path: SITE_URLS.PATIENT_LIST,
        children: [
          {
            index: true,
            element: <PatientListView />,
          },
          {
            path: SITE_URLS.PATIENT_VIEW,
            element: <PatientView />,
          },
        ],
      },
      {
        path: SITE_URLS.ADMIN_VIEW,
        element: (
          <ProtectedRoute>
            <AdminView />
          </ProtectedRoute>
        ),
      },
      {
        path: SITE_URLS.ADMIN_RESIDENT_DEPARTMENT_VIEW,
        element: (
          <ProtectedRoute>
            <AdminResidentDepartment />
          </ProtectedRoute>
        ),
      },
      {
        path: SITE_URLS.ADMIN_UNIT_VIEW,
        element: (
          <ProtectedRoute>
            <AdminUnit />
          </ProtectedRoute>
        ),
      },
    ],
  },
];

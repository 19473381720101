import { ptj as ptjComponentLibraryTheme } from "@collabodoc/component-library";
import collabodocLogo from "../Images/logo/collabodoc_light.png";
import logo from "../Images/logo/ptj_light.png";
import MedicalRoundTheme from "./MedicalRoundTheme";

const ptj: MedicalRoundTheme = {
  ...ptjComponentLibraryTheme,
  logo: logo,
  overrideLogo: collabodocLogo,
  card: {
    cardHeader: {
      backgroundColor: ptjComponentLibraryTheme.colors.primary,
      color: "white",
    },
    selectedCard: {
      backgroundColor: "#cce3ef",
      color: ptjComponentLibraryTheme.colors.black,
      borderColor: ptjComponentLibraryTheme.colors.secondary,
    },
    message: {
      selfBackground: "#cce3ef",
      otherBackground: ptjComponentLibraryTheme.colors.disabled,
    },
    hover: {
      backgroundColor: "#F9B360",
    },
  },
  filter: {
    backgroundColor: "#cce3ef",
    color: ptjComponentLibraryTheme.colors.primary,
  },
  pagination: {
    active: ptjComponentLibraryTheme.colors.focus,
    dark: ptjComponentLibraryTheme.colors.primary,
    light: ptjComponentLibraryTheme.colors.white,
  },
  notification: {
    unread: "#cce3ef",
  },
  dropdown: {
    groupHeaderColor: "#cce3ef",
  },
  issueCardHeader: {
    backgroundColor: ptjComponentLibraryTheme.colors.secondary,
    color: ptjComponentLibraryTheme.colors.white,
  },
  issueCardRow: {
    selected: "#cce3ef",
    hover: "#F9B360",
  },
};

export default ptj;

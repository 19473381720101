import { PatientListPatientModel } from "../apiClient";

const filterPatients = (
  patients: PatientListPatientModel[] = [],
  searchString = ""
): PatientListPatientModel[] => {
  if (searchString) {
    return multiFilter(patients, searchString, [
      "name",
      "personalNumber",
    ]) as PatientListPatientModel[];
  }
  return patients;
};

const multiFilter = (
  objects: { [key: string]: any }[] = [],
  searchString = "",
  properties: string[] = []
) => {
  const searchWords = searchString.split(" ");
  return objects.filter((object) =>
    searchWords.every((searchWord) =>
      properties.some((property) =>
        object[property].toLowerCase().includes(searchWord.toLowerCase())
      )
    )
  );
};

export { filterPatients, multiFilter };

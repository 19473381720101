import {
  CheckboxWithLabel,
  DropDown,
  H2,
  InputLabel,
    LoadingView,
  PrimaryButton,
  SecondaryButton,
  TextInputWithLabelAndError,
} from "@collabodoc/component-library";
import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { generatePath, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { GlobalContext } from "../../context/GlobalContext";
import { SITE_URLS } from "../../enums/Urls";
import { device } from "../../Content/Style/devices";
import { PatientClient } from "../../apiClient";
import {
  getFormattedPersonId,
  isValidPersonalNumber,
} from "../../utils/personalNumber";

const defaultFormValues = {
  personId: "19",
  isPersonalNumber: true,
  firstname: "",
  lastname: "",
  residentDepartmentId: "",
  unitId: "",
  careCenterId: "",
};

interface CreatePatientModalProps {
  showCreatePatientModal: boolean;
  setShowCreatePatientModal: (value: boolean) => void;
}

export function isEmptyGuid(guidString: string) {
  return guidString === "00000000-0000-0000-0000-000000000000";
}

const CreatePatientModal = ({
  showCreatePatientModal,
  setShowCreatePatientModal,
}: CreatePatientModalProps) => {
  const [formValues, setFormValues] = useState(defaultFormValues);
  const [existingPatientId, setExistingPatientId] = useState<string>();
  const [error, setError] = useState("");
  const [isLoadingPatient, setIsLoadingPatient] = useState(false);
  const {
    residentDepartments = [],
    careCenters = [],
    accessToken,
  } = useContext(GlobalContext);
  const client = new PatientClient({ accessToken });
  const navigate = useNavigate();
  const { showCareCenter } = useContext(GlobalContext);

  const handlePersonIdChange = async (
    personId: string,
    isPersonalNumber: boolean,
  ) => {
    updateForm({ personId: personId });
    setError("");
    if (!personId) {
      return;
    }
    if (isPersonalNumber && !isValidPersonalNumber(personId)) {
      setError("Ogiltigt personnummer");
    } else {
      fetchPatient(
        isPersonalNumber ? getFormattedPersonId(personId) : personId,
        isValidPersonalNumber(personId),
      );
    }
  };
  const fetchPatient = (personId: string, isPersonalNumber: boolean) => {
    setError("");
    setIsLoadingPatient(true);
    client
      .getPatientByPersonId(personId)
      .then((patient) => {
        if (!isEmptyGuid(patient.id)){
          setExistingPatientId(patient.id);
          setError(
            `En patient vid namn ${patient.name} finns redan med detta personnummer`,
          )
        }
        else {
            updateForm({firstname: patient.firstName, lastname: patient.lastName})
        }
      })
      .catch((details) => {
        if (details.status !== 404) {
          setError("Något gick fel");
        }
      })
      .finally(() => setIsLoadingPatient(false));
  };

  
  
  const toggleHasPersonalNumber = (missingPnr: boolean) => {
    setError("");
    updateForm({
      isPersonalNumber: !missingPnr,
      personId: missingPnr ? formValues.personId : "19",
    });
    missingPnr && fetchPatient(formValues.personId, missingPnr);
  };

  const handleHide = () => {
    setExistingPatientId(undefined);
    setFormValues(defaultFormValues);
    setShowCreatePatientModal(false);
  };

  const updateForm = (newValues: Partial<typeof defaultFormValues>) => {
    setExistingPatientId(undefined);
    setFormValues((v) => ({ ...v, ...newValues }));
  };

  const selectedResidentDepartment = residentDepartments.find(
    (x) => x.id === formValues.residentDepartmentId,
  );

  const residentDepartmentOptions = residentDepartments
    .filter((r) => r.userCanEditPatients)
    .map((x) => ({
      text: x.name,
      id: x.id,
    }));

  const selectedUnit = selectedResidentDepartment?.units.find(
    (x) => x.unitId === formValues.unitId,
  );

  const unitOptions =
    selectedResidentDepartment?.units.map((unit) => ({
      text: unit.name,
      id: unit.unitId,
    })) || [];

  const selectedCareCenter = careCenters.find(
    (cc) => cc.id === formValues.careCenterId,
  );

  const careCenterOptions = careCenters
    .filter(
      (r) =>
        !selectedUnit?.defaultCareCenterId ||
        r.id === selectedUnit?.defaultCareCenterId,
    )
    .map((cc) => ({
      text: cc.name,
      id: cc.id,
    }));

  const handleCreatePatient = async () => {
    if (existingPatientId) {
      navigate(
        generatePath(SITE_URLS.PATIENT_VIEW, { patientId: existingPatientId }),
        {
          state: {
            isNewIssue: true,
          },
        },
      );
    } else {
      const careCenterId =
        formValues.careCenterId || selectedUnit?.defaultCareCenterId;
      if (!careCenterId) throw new Error("Carecenter id not set");
      client
        .createPatient({
          unitId: formValues.unitId,
          personId: formValues.personId,
          firstname: formValues.firstname,
          lastname: formValues.lastname,
          hasPersonalNumber: formValues.isPersonalNumber,
          careCenterId: careCenterId,
        })
        .then(({ patientId }) => {
          navigate(
            generatePath(SITE_URLS.PATIENT_VIEW, {
              patientId: patientId,
            }),
            {
              state: {
                isNewIssue: true,
              },
            },
          );
        });
    }
  };

  useEffect(() => {
    updateForm({ unitId: "" });
  }, [formValues.residentDepartmentId]);

  useEffect(() => {
    updateForm({ careCenterId: selectedUnit?.defaultCareCenterId });
  }, [selectedUnit?.defaultCareCenterId]);

  useEffect(() => {
    updateForm({
      firstname: defaultFormValues.firstname,
      lastname: defaultFormValues.lastname,
      unitId: defaultFormValues.unitId,
      residentDepartmentId: defaultFormValues.residentDepartmentId,
    });
  }, [formValues.personId]);

  const isValidPnr = isValidPersonalNumber(formValues.personId);
  const showInputs =
    (!isLoadingPatient || !formValues.isPersonalNumber) &&
    !existingPatientId &&
    (isValidPnr || !formValues.isPersonalNumber);

  const submitDisabled =
    Boolean(!existingPatientId) &&
    (isLoadingPatient ||
      !formValues.personId ||
      !formValues.firstname ||
      !formValues.lastname ||
      !formValues.residentDepartmentId ||
      !formValues.unitId);

  return (
      <Modal show={showCreatePatientModal} onHide={handleHide}>
          <Modal.Header>
              <StyledH2>Ny patient</StyledH2>
          </Modal.Header>
          <Modal.Body>
              <ModalInputDiv>
                  <TextInputWithLabelAndError
                      label={
                          formValues.isPersonalNumber
                              ? "Personnummer (ÅÅÅÅMMDD-XXXX)"
                              : "Reservnummer / samordningsnummer / födelsenummer"
                      }
                      maxLength={20}
                      placeholder={
                          formValues.isPersonalNumber
                              ? "ÅÅÅÅMMDD-XXXX"
                              : "Reservnummer / samordningsnummer / födelsenummer"
                      }
                      value={formValues.personId}
                      handleChange={(e) =>
                          handlePersonIdChange(e, formValues.isPersonalNumber)
                      }
                  />
                  {error && <InputError>{error}</InputError>}
                  <CheckboxWithLabel
                      label={"Personnummer saknas"}
                      checked={!formValues.isPersonalNumber}
                      onChange={(e) => toggleHasPersonalNumber(e.target.checked)}
                  />
              </ModalInputDiv>
              {isLoadingPatient && <LoadingView/>}
              {showInputs && (
                  <>
                      <ModalInputDiv>
                          <TextInputWithLabelAndError
                              label={"Förnamn"}
                              value={formValues.firstname}
                              handleChange={(e) => updateForm({firstname: e})}
                          />
                      </ModalInputDiv>
                      <ModalInputDiv>
                          <TextInputWithLabelAndError
                              label={"Efternamn"}
                              value={formValues.lastname}
                              handleChange={(e) => updateForm({lastname: e})}
                          />
                      </ModalInputDiv>
                      <ModalInputDiv>
                          <InputLabel>Boende</InputLabel>
                          <DropDown
                              placeholder={"Välj boende"}
                              options={residentDepartmentOptions}
                              handler={(id) =>
                                  updateForm({
                                      residentDepartmentId: id as string,
                                      unitId: "",
                                  })
                              }
                              sort={true}
                              selected={selectedResidentDepartment?.name}
                          />
                      </ModalInputDiv>
                      <ModalInputDiv>
                          <InputLabel>Avdelning</InputLabel>
                          <DropDown
                              placeholder={"Välj avdelning"}
                              options={unitOptions}
                              handler={(id) => updateForm({unitId: id as string})}
                              selected={selectedUnit?.name}
                              isDisabled={!selectedResidentDepartment}
                          />
                      </ModalInputDiv>
                      {showCareCenter && (
                          <ModalInputDiv>
                              <InputLabel>Vårdcentral</InputLabel>
                              <DropDown
                                  placeholder={"Välj vårdcentral"}
                                  options={careCenterOptions}
                                  handler={(id) => updateForm({careCenterId: id as string})}
                                  selected={selectedCareCenter?.name}
                                  isDisabled={!selectedUnit}
                              />
                          </ModalInputDiv>
                      )}
                  </>
              )}
          </Modal.Body>
          <Footer>
              <SecondaryButton onClick={handleHide}>Avbryt</SecondaryButton>
              <PrimaryButton
                  disabled={submitDisabled}
                  onClick={() => handleCreatePatient()}
              >
                  {existingPatientId ? "Gå till patient" : "Lägg till patient"}
              </PrimaryButton>
          </Footer>
      </Modal>
  );
};

export default CreatePatientModal;

const StyledH2 = styled(H2)`
  margin: 0;
`;

const ModalInputDiv = styled.div`
  margin-bottom: 10px;

  > label {
    margin-bottom: 0;
  }
`;

const Footer = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between;

  @media ${device.tablet} {
    flex-direction: column-reverse;
    align-items: unset;

    button {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
    }
  }
`;

const InputError = styled.i`
  font-size: 14px;
`;

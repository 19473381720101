import styled from "styled-components";
import { Link } from "react-router-dom";
import { device } from "../../Content/Style/devices";
import { DangerButton, PrimaryButton } from "@collabodoc/component-library";

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.typography.linkColor};
`;

export const Ul = styled.ul`
  padding: 0.75rem;
  margin-bottom: 0;
  list-style-type: none;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-items: center;
`;
export const Li = styled.li`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const Container = styled.main`
  margin-left: 7.5rem;
  margin-right: 7.5rem;
  padding: 1rem;
  background-color: white;

  @media ${device.tablet} {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1 auto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  & > div {
    flex: 1;
  }
`;

export const Form = styled.form`
  margin-bottom: 2rem;
`;

export const NoWrapButton = styled(PrimaryButton)`
  margin-left: 2rem;
  white-space: nowrap;
`;

export const NoWrapDangerButton = styled(DangerButton)`
  margin-left: 2rem;
  white-space: nowrap;
`;

export const DropDownWrapper = styled.div`
  width: 20rem;
  margin-right: 4rem;
`;

import styled from "styled-components";
import { size } from "../../../Content/Style/devices";
import { SecondaryButton } from "@collabodoc/component-library";

export const ContentRow = styled.div<{
  $isSender: boolean;
}>`
  min-width: 45%;
  max-width: 75%;
  align-self: ${({ $isSender }) => ($isSender ? "flex-end" : "flex-start")};

  @media (max-width: ${size.tablet}) {
    min-width: unset;
    max-width: unset;
    width: 100%;
  }
`;

export const ContentBoxWrapper = styled.div<{
  $isSender: boolean;
}>`
  display: flex;
  flex-direction: ${({ $isSender }) => ($isSender ? "row" : "row-reverse")};
  align-items: center;
  gap: 4px;
`;

export const CopyButton = styled(SecondaryButton)`
  border: none;
  padding: 0.15rem 0.45rem;

  :hover {
    border: none;
  }

  :active {
    border: none;
  }
`;
export const ContentBox = styled.div<{
  $isSender: boolean;
  $isClosed: boolean;
}>`
  flex: 1;
  flex-direction: row;
  padding: 10px;
  border-radius: 5px;
  background-color: ${({ theme, $isSender }) =>
    $isSender
      ? theme.card.message.selfBackground
      : theme.card.message.otherBackground};
  filter: ${({ $isClosed }) => $isClosed && "grayscale(1)"};
`;

export const ContentText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  overflow-wrap: anywhere;
  white-space: break-spaces;
  margin-bottom: 0;
`;

export const ContentReceivers = styled.div`
  overflow-wrap: anywhere;
  white-space: break-spaces;
  margin-bottom: 0;
  margin-top: 0;
  font-size: x-small;
`;

export const ContentSender = styled.div<{
  $isSender: boolean;
}>`
  display: flex;
  justify-content: space-between;
  padding: 2px 2px 0 2px;
  margin: ${({ $isSender }) => ($isSender ? "0 0 0 30px" : "0 30px 0 0")};
  font-size: x-small;
  font-style: italic;
`;

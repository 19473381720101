import { OidcProvider, OidcSecure } from "@axa-fr/react-oidc";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { FontProvider } from "./Content/fonts";
import { getTheme } from "./Content/themes/getTheme";
import oidcConfiguration from "./OidcConfiguration";
import { CustomSessionLost } from "./components/CustomSessionLost";
import VersionLoadingView from "./components/VersionLoadingView";
import { routes } from "./routes";

const theme = getTheme();
const router = createBrowserRouter(routes);
const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <StyledContainer>
        <FontProvider />
        <OidcProvider
          configuration={oidcConfiguration}
          authenticatingComponent={VersionLoadingView}
          callbackSuccessComponent={VersionLoadingView}
          sessionLostComponent={CustomSessionLost}
          loadingComponent={VersionLoadingView}
        >
          <OidcSecure>
            <RouterProvider router={router} />
          </OidcSecure>
        </OidcProvider>
      </StyledContainer>
    </ThemeProvider>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh; //Fallback unit if dvh is not supported by the browser version
  height: 100dvh;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  padding-top: 56px;
`;

export default App;

import { H5, SecondaryButton } from "@collabodoc/component-library";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { IssueModel, IssueStatus } from "../../apiClient";
import {
  CardBody,
  CardComponent,
  CardHeader,
  CardHeaderText,
} from "../../Content/Style/cardstyles";
import { device, size } from "../../Content/Style/devices";
import { GlobalContext } from "../../context/GlobalContext";
import { formatDateTime } from "../../utils/dateUtils";
import { PatientViewContext } from "./PatientView";
import { Tag } from "../PatientListView/CollapsableRow";

interface PatientIssuesProps {
  issues: IssueModel[];
  handleNewIssueButtonClick: () => void;
}

const PatientIssues = ({
  issues = [],
  handleNewIssueButtonClick,
}: PatientIssuesProps) => {
  const { selectableContactReasons } = useContext(GlobalContext);

  const unAnsweredIssues = issues.filter((issue) => issue.userHasBall);
  const closedIssues = issues.filter(
    (issue) => issue.status === IssueStatus.Completed,
  );

  const otherIssues = issues.filter(
    (issue) =>
      !unAnsweredIssues
        .map((unAnsweredIssue) => unAnsweredIssue.issueId)
        .includes(issue.issueId) &&
      !closedIssues
        .map((closedIssue) => closedIssue.issueId)
        .includes(issue.issueId),
  );

  return (
    <StyledCardComponent>
      <DisplayIfIsNotTabletCardHeader>
        <CardHeaderText>Ärenden</CardHeaderText>
      </DisplayIfIsNotTabletCardHeader>
      <StyledCardBody>
        <PatientIssueListWrapper>
          {selectableContactReasons.length > 0 && (
            <SecondaryButton onClick={handleNewIssueButtonClick}>
              Nytt ärende
            </SecondaryButton>
          )}
          <PatientIssueList label={"Obesvarade"} issues={unAnsweredIssues} />
          <PatientIssueList label={"Övriga"} issues={otherIssues} />
          <PatientIssueList label={"Avslutade"} issues={closedIssues} />
        </PatientIssueListWrapper>
      </StyledCardBody>
    </StyledCardComponent>
  );
};

const showUnread = (issue: IssueModel, currentlyReadIssues: number[]) =>
  !issue.isReadByUser &&
  issue.userHasBall &&
  !currentlyReadIssues.includes(issue.issueId);

interface PatientIssuesListProps {
  issues: IssueModel[];
  label: string;
}

const PatientIssueList = ({ issues = [], label }: PatientIssuesListProps) => {
  const { selectedIssueId, setSelectedIssueId } =
    useContext(PatientViewContext);
  const isTablet = useMediaQuery({ query: device.tablet });
  const [showIssues, setShowIssues] = useState(false);
  const [currentlyReadIssues, setCurrentlyReadIssues] = useState<number[]>([]);

  useEffect(() => {
    if (issues.some((issue) => issue.issueId === selectedIssueId)) {
      if (!currentlyReadIssues.includes(selectedIssueId)) {
        setCurrentlyReadIssues([...currentlyReadIssues, selectedIssueId]);
      }
      setShowIssues(true);
    }
  }, [issues, selectedIssueId, currentlyReadIssues]);

  return (
    <>
      {label !== "Avslutade" && (
        <StyledIssueListCardComponent>
          <StyledCardHeader
            $show={showIssues}
            onClick={() => setShowIssues(!showIssues)}
          >
            <StyledCardHeaderText>
              {label} {`(${issues.length})`}
            </StyledCardHeaderText>
            <FontAwesomeIcon icon={showIssues ? faChevronUp : faChevronDown} />
          </StyledCardHeader>
          <CardBodyBorder $show={showIssues}>
            {issues.length > 0 ? (
              <></>
            ) : (
              <IssueTableHeader>
                <SmallText>Inga ärenden</SmallText>
              </IssueTableHeader>
            )}
            {issues.map((issue) => (
              <IssueRow
                key={issue.issueId}
                $isSelected={selectedIssueId === issue.issueId}
                onClick={() => setSelectedIssueId(issue.issueId)}
              >
                <Line />
                <IssueDiv>
                  <IssueInfoWrapper>
                    <IssueHeader>
                      {showUnread(issue, currentlyReadIssues) && (
                        <FontAwesomeIcon icon={faEnvelope} />
                      )}
                      <span>{issue.contactReason}</span>
                    </IssueHeader>
                    <LatestEvent>
                      {formatDateTime(issue.timeOfLastActivity) ||
                        "Inga händelser"}
                    </LatestEvent>
                  </IssueInfoWrapper>
                  <TagWrapper>
                    {issue.tags.map((t) => (
                      <Tag key={t.id}>{t.name}</Tag>
                    ))}
                  </TagWrapper>
                </IssueDiv>
              </IssueRow>
            ))}
          </CardBodyBorder>
        </StyledIssueListCardComponent>
      )}

      {label === "Avslutade" && (
        <StyledClosedIssueListCardComponent>
          <DisabledCardHeader
            $show={showIssues}
            onClick={() => setShowIssues(!showIssues)}
          >
            <DisabledCardHeaderText>
              Avslutade {`(${issues.length})`}
            </DisabledCardHeaderText>
            <FontAwesomeIcon icon={showIssues ? faChevronUp : faChevronDown} />
          </DisabledCardHeader>
          <CardBodyBorder $show={showIssues}>
            {issues.length > 0 ? (
              <></>
            ) : (
              <IssueTableHeader>
                <SmallText>Inga ärenden</SmallText>
              </IssueTableHeader>
            )}
            {issues.map((issue) => (
              <ClosedIssueRow
                $isSelected={selectedIssueId === issue.issueId}
                onClick={() => setSelectedIssueId(issue.issueId)}
                key={issue.issueId}
              >
                <Line />
                <ClosedIssueDiv>
                  <IssueInfoWrapper>
                    <IssueHeader>{issue.contactReason}</IssueHeader>
                    <LatestEvent>
                      {formatDateTime(issue.timeOfLastActivity) ||
                        "Inga händelser"}
                    </LatestEvent>
                  </IssueInfoWrapper>
                  <TagWrapper>
                    {issue.tags.map((t) => (
                      <Tag key={t.id}>{t.name}</Tag>
                    ))}
                  </TagWrapper>
                </ClosedIssueDiv>
              </ClosedIssueRow>
            ))}
          </CardBodyBorder>
        </StyledClosedIssueListCardComponent>
      )}
    </>
  );
};

export const StyledCardComponent = styled(CardComponent)`
  margin-bottom: 0;
  overflow: hidden;
  flex: 1;
  min-width: 250px;

  @media ${device.tablet} {
    padding: 0.75rem;
    border-radius: 0 0 10px 10px;
  }
`;

const StyledIssueListCardComponent = styled(CardComponent)`
  border-radius: 5px;
  margin: 0;
`;

const StyledClosedIssueListCardComponent = styled(StyledIssueListCardComponent)`
  padding-bottom: 12px;
`;

export const DisplayIfIsNotTabletCardHeader = styled(CardHeader)`
  @media ${device.tablet} {
    display: none;
  }
`;

export const StyledCardBody = styled(CardBody)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const PatientIssueListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (min-width: ${size.tablet}) {
    max-height: 1px;
  }
`;

export const StyledCardHeader = styled(CardHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) => props.theme.issueCardHeader.backgroundColor};
  border: none;
  padding: 8px 15px;
  border-radius: ${(props) =>
    !props.$show ? "5px" : "5px 5px 0 0"} !important;
  user-select: none;

  > svg {
    color: ${(props) => props.theme.issueCardHeader.color};
  }
`;

const StyledCardHeaderText = styled(CardHeaderText)`
  color: ${(props) => props.theme.issueCardHeader.color};
  margin: 0;
`;

const CardBodyBorder = styled.div<{ $show: boolean }>`
  display: ${(props) => !props.$show && "none"};
  border: 1px solid #e4e4e4;
  border-top: none;
  border-radius: 0 0 5px 5px;
`;

const Line = styled.hr`
  margin: 0;
`;

const IssueTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px;
`;

const IssueRow = styled.div<{ $isSelected: boolean }>`
  cursor: pointer;
  background-color: ${(props) =>
    props.$isSelected
      ? props.theme.issueCardRow.selected
      : props.theme.colors.white};

  &:hover {
    background-color: ${({ theme }) => theme.issueCardRow.hover};
  }

  :last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const ClosedIssueRow = styled(IssueRow)`
  background-color: ${(props) => (props.$isSelected ? "#e4e4e4" : "#fff")};

  &:hover {
    background-color: #cdcdcd;
  }
`;

const IssueDiv = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 6px;
  padding: 0.75rem 0.625rem;

  :last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  @media ${device.tablet} {
    flex-direction: row;
  }
`;

const IssueInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const ClosedIssueDiv = styled(IssueDiv)`
  filter: grayscale(1);
`;

const DisabledCardHeader = styled(CardHeader)`
  background-color: #e4e4e4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: none;
  padding: 8px 15px;
  border-radius: ${(props) =>
    !props.$show ? "5px" : "5px 5px 0 0"} !important;
  user-select: none;

  > svg {
    color: ${(props) => props.theme.colors.black};
  }
`;

const DisabledCardHeaderText = styled(CardHeaderText)`
  color: ${(props) => props.theme.colors.black};
  margin: 0;
`;

const IssueHeader = styled(H5)`
  margin-bottom: 0;
  display: flex;
  align-items: center;
  font-weight: bold;

  > svg {
    margin-right: 0.25rem;
  }
`;

const SmallText = styled.span`
  font-size: small;
`;

const LatestEvent = styled(SmallText)`
  min-width: 95px;
  margin-top: 2px;
`;

const TagWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  flex-flow: wrap;
  justify-content: end;
  overflow: hidden;
`;

export default PatientIssues;

import { hasAdminRole } from "@collabodoc/component-library";
import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { GlobalContext } from "./context/GlobalContext";

const ProtectedRoute = ({ redirectPath = "/", children }) => {
  const { oidcUser } = useContext(GlobalContext);
  const isAllowed = oidcUser && hasAdminRole(oidcUser?.role);

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;

import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
} from "react";
import { FILTER_KEYS } from "../enums/enums";
import { getRequestInit, useFetchy } from "@collabodoc/component-library";
import { PatientListPatientModel } from "../apiClient";
import { API_URLS, SITE_URLS } from "../enums/Urls";
import { hasBeenDisconnected, hubConnection } from "../utils/hubConnection";
import { GlobalContext } from "./GlobalContext";
import { useLocation } from "react-router-dom";
import { getDefaultFilters } from "../hooks/usePatientListFilters";
import VersionLoadingView from "../components/VersionLoadingView";
import { HubConnectionState } from "@microsoft/signalr";

const getPatientListQuery = (
  pathname: string,
  search: string,
  clinicRole: string | undefined,
) => {
  switch (pathname) {
    case SITE_URLS.PATIENT_LIST:
      return new URLSearchParams(search).toString();
    case "/":
      return getDefaultFilters(clinicRole);
    default:
      return window.sessionStorage.getItem("patientListParams") || "";
  }
};

interface PatientListContext {
  patientList: PatientListPatientModel[];
  isLoadingPatientList: boolean;
  patientListUrl: string;
}

export const PatientListContext = createContext({} as PatientListContext);
export const PatientListContextProvider = ({ children }: PropsWithChildren) => {
  const { accessToken, clinicRole, hubConnectionState } =
    useContext(GlobalContext);
  const { pathname, search } = useLocation();
  const patientListQuery = getPatientListQuery(pathname, search, clinicRole);

  const {
    data: patientList,
    isLoading: isLoadingPatientList,
    doFetch: reFetchPatientList,
  } = useFetchy<PatientListPatientModel[]>(
    `${API_URLS.PATIENTLIST}?${patientListQuery}`,
    getRequestInit({ accessToken }),
  );

  useEffect(() => {
    const patientListParams = new URLSearchParams(patientListQuery);
    const currentResidentDepartmentIds = patientListParams.getAll(
      FILTER_KEYS.RESIDENT_DEPARTMENT_ID,
    );
    const currentCareCenterIds = patientListParams.getAll(
      FILTER_KEYS.CARE_CENTER_ID,
    );
    const possiblyReFetchPatientList = async (
      residentDepartmentId: string,
      careCenterId: string,
    ) => {
      if (
        (currentCareCenterIds.length === 0 ||
          currentCareCenterIds.includes(careCenterId)) &&
        (currentResidentDepartmentIds.length === 0 ||
          currentResidentDepartmentIds.includes(residentDepartmentId))
      )
        reFetchPatientList().finally(() =>
          console.debug("Patientlist refetch triggered by SignalR"),
        );
    };

    hubConnection.on("patientUpdated", possiblyReFetchPatientList);
    return () => {
      hubConnection.off("patientUpdated", possiblyReFetchPatientList);
    };
  }, [patientListQuery, reFetchPatientList]);

  useEffect(() => {
    if (
      hubConnectionState === HubConnectionState.Connected &&
      hasBeenDisconnected
    ) {
      reFetchPatientList();
    }
  }, [hubConnectionState, reFetchPatientList]);

  if (!patientList) return <VersionLoadingView />;

  return (
    <PatientListContext.Provider
      value={{
        patientList: patientList || [],
        isLoadingPatientList,
        patientListUrl: `${SITE_URLS.PATIENT_LIST}?${patientListQuery}`,
      }}
    >
      {children}
    </PatientListContext.Provider>
  );
};

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import Attachment from "./Attachment";
import { formatDateTime } from "../../../utils/dateUtils";
import { MessageModel, UserViewModel } from "../../../apiClient";
import styled from "styled-components";
import React from "react";
import {
  ContentBox,
  ContentBoxWrapper,
  ContentReceivers,
  ContentRow,
  ContentSender,
  ContentText,
  CopyButton,
} from "./ContentComponents";

interface MessageProps {
  message: MessageModel;
  user: UserViewModel;
  timestamp: string;
  isIssueClosed: boolean;
  isSender: boolean;
}

export const Message = ({
  message,
  user,
  timestamp,
  isIssueClosed,
  isSender,
}: MessageProps) => {
  return (
    <ContentRow $isSender={isSender}>
      <ContentBoxWrapper $isSender={isSender}>
        <CopyButton
          type={"button"}
          onClick={() => navigator.clipboard.writeText(message.text)}
        >
          <FontAwesomeIcon icon={faCopy} />
        </CopyButton>
        <ContentBox $isClosed={isIssueClosed} $isSender={isSender}>
          <ContentReceivers>
            Till:&nbsp;
            {message.receiverRoles.map((role) => role.roleName).join(", ")}
          </ContentReceivers>
          <ContentText>{message.text}</ContentText>

          {message.attachments.length > 0 && (
            <AttachmentsWrapper>
              {message.attachments.map((file) => (
                <Attachment
                  key={file.fileId}
                  issueId={message.issueId}
                  file={file}
                />
              ))}
            </AttachmentsWrapper>
          )}
        </ContentBox>
      </ContentBoxWrapper>
      <ContentSender $isSender={isSender}>
        <span>
          {user.name}, {user.roleName}
        </span>
        <span>{formatDateTime(timestamp)}</span>
      </ContentSender>
    </ContentRow>
  );
};

const AttachmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

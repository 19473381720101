import { CheckboxWithLabel } from "@collabodoc/component-library";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { USER_ROLE_NAMES, USER_ROLES } from "../../enums/enums";
import { getSelectableRoles } from "../../functions/roleFunctions";
import { PatientViewContext } from "./PatientView";
import { UserRoles } from "../../apiClient";

interface RecipientSelectProps {
  receiverRoleIds: UserRoles[];
  handleChangeReceiverRoles: (value: UserRoles) => void;
  currentRoles: UserRoles[];
  clinicRole: string;
  setRecipientsModalOpen: (value: boolean) => void;
  handleSelectAllRecipients: (
    otherSelectable: { text: string; id: UserRoles }[],
    allSelected: boolean,
  ) => void;
  setReceiverRoleIds: (value: UserRoles[]) => void;
  defaultReceiver: UserRoles | undefined;
  isNewIssue: boolean;
}
const RecipientSelect = ({
  receiverRoleIds,
  handleChangeReceiverRoles,
  currentRoles,
  clinicRole,
  setRecipientsModalOpen,
  handleSelectAllRecipients,
  setReceiverRoleIds,
  defaultReceiver,
  isNewIssue,
}: RecipientSelectProps) => {
  const { residentDepartmentUserRoles } = useContext(PatientViewContext);

  const selectableRoles = getSelectableRoles(
    residentDepartmentUserRoles,
    currentRoles,
    clinicRole,
    receiverRoleIds,
  );

  const clinicRoleId = USER_ROLES[clinicRole];
  const mySelectable = selectableRoles.find((role) => role.id === clinicRoleId);
  const otherSelectable = selectableRoles.filter(
    (role) => role.id !== clinicRoleId,
  );

  const showRecipientModalButton = currentRoles?.length !== 1;

  const allSelected = otherSelectable.every(({ id }) => {
    return receiverRoleIds.includes(id);
  });

  const ownRoleSelected = receiverRoleIds.includes(clinicRoleId);

  useEffect(() => {
    if (selectableRoles.length === 1 && receiverRoleIds.length === 0) {
      setReceiverRoleIds([selectableRoles[0].id]);
    } else if (defaultReceiver && isNewIssue) {
      setReceiverRoleIds([defaultReceiver]);
    }
  }, []);

  return (
    <RecipientsWrapper>
      <RecipientsLabel>Välj mottagare</RecipientsLabel>
      <MessageRecipients>
        {mySelectable && clinicRole && (
          <>
            <CheckBoxWrapper>
              <CheckboxWithLabel
                checked={receiverRoleIds.includes(clinicRoleId)}
                disabled={
                  receiverRoleIds.length > 0 &&
                  !receiverRoleIds.includes(clinicRoleId)
                }
                key={clinicRoleId}
                label={USER_ROLE_NAMES[clinicRoleId]}
                onChange={() => handleChangeReceiverRoles(clinicRoleId)}
              />
            </CheckBoxWrapper>
            {otherSelectable.length > 0 && (
              <CheckboxSeparator>
                <br />
              </CheckboxSeparator>
            )}
          </>
        )}
        {otherSelectable.map((ro) => {
          return (
            <CheckBoxWrapper key={ro.id}>
              <CheckboxWithLabel
                checked={receiverRoleIds.includes(ro.id)}
                disabled={ownRoleSelected}
                key={ro.id}
                label={ro.text}
                onChange={() => handleChangeReceiverRoles(ro.id)}
              />
            </CheckBoxWrapper>
          );
        })}
        {selectableRoles.length > 1 && (
          <CheckBoxWrapper>
            <CheckboxWithLabel
              checked={allSelected}
              disabled={ownRoleSelected}
              key={"alla"}
              label={"Alla"}
              onChange={() =>
                handleSelectAllRecipients(otherSelectable, allSelected)
              }
            />
          </CheckBoxWrapper>
        )}
        {showRecipientModalButton && (
          <span
            onClick={
              !ownRoleSelected ? () => setRecipientsModalOpen(true) : undefined
            }
          >
            <ChevronIcon icon={faPlus} $disabled={ownRoleSelected} />
          </span>
        )}
      </MessageRecipients>
    </RecipientsWrapper>
  );
};

const ChevronIcon = styled(FontAwesomeIcon)<{ $disabled: boolean }>`
  color: ${({ theme, $disabled }) =>
    $disabled ? "#c8cacc" : theme.colors.primary};
  pointer-events: ${({ $disabled }) => $disabled && "none"};

  &:hover {
    cursor: pointer;
  }
`;

const RecipientsWrapper = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const RecipientsLabel = styled.div`
  font-size: 16px;
  margin: 0;
  font-weight: bold;
`;

const MessageRecipients = styled.span`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid black;
  border-radius: 8px;
  gap: 0 8px;
  padding: 6px 8px;

  > svg {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const CheckboxSeparator = styled.div`
  border-right: 1px solid black;
`;

const CheckBoxWrapper = styled.div`
  > label {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding-top: 0;
    padding-left: 18px;

    > span::before {
      top: 4px;
    }

    > span::after {
      top: 5px;
    }
  }
`;

export default RecipientSelect;

import {
  CheckboxWithLabel,
  H2,
  SecondaryButton,
} from "@collabodoc/component-library";
import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { USER_ROLES } from "../../enums/enums";
import { PatientViewContext } from "./PatientView";
import { device } from "../../Content/Style/devices";
import { UserRoles } from "../../apiClient";

interface RecipientModalProps {
  receiverRoles: UserRoles[];
  setReceiverRoles: (value: UserRoles) => void;
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  handleSelectAllRecipients: (
    selectableRoles: { text: string; id: UserRoles }[],
    allRolesSelected: boolean
  ) => void;
  clinicRole: UserRoles;
}
export const RecipientModal = ({
  receiverRoles,
  setReceiverRoles,
  modalOpen,
  setModalOpen,
  handleSelectAllRecipients,
  clinicRole,
}: RecipientModalProps) => {
  const { residentDepartmentUserRoles } = useContext(PatientViewContext);
  const selectableRoles = residentDepartmentUserRoles
    .filter(({ role }) => clinicRole && role !== USER_ROLES[clinicRole])
    .map(({ role, roleName }) => ({
      text: roleName,
      id: role,
    }));

  const allRolesSelected = selectableRoles?.every(({ id }) => {
    return receiverRoles.includes(id);
  });

  return (
    <Modal show={modalOpen} onHide={() => setModalOpen(false)}>
      <Modal.Header>
        <StyledH2>Lägg till mottagare</StyledH2>
      </Modal.Header>
      <Modal.Body>
        <CheckBoxWrapper>
          <CheckboxWithLabel
            checked={allRolesSelected}
            key={"alla"}
            label={"Alla"}
            onChange={() =>
              handleSelectAllRecipients(selectableRoles, allRolesSelected)
            }
          />
        </CheckBoxWrapper>
        {selectableRoles?.map((ro) => {
          return (
            <CheckBoxWrapper key={ro.id}>
              <CheckboxWithLabel
                checked={receiverRoles.includes(ro.id)}
                key={ro.id}
                label={ro.text}
                onChange={() => setReceiverRoles(ro.id)}
              />
            </CheckBoxWrapper>
          );
        })}
      </Modal.Body>
      <ModalFooter>
        <SecondaryButton onClick={() => setModalOpen(false)}>
          Stäng
        </SecondaryButton>
      </ModalFooter>
    </Modal>
  );
};

const StyledH2 = styled(H2)`
  margin: 0;
`;

const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between;

  @media ${device.tablet} {
    align-items: unset;

    button {
      display: flex;
      flex: 1;
      justify-content: center;
    }
  }
`;

const CheckBoxWrapper = styled.div`
  > label {
    display: flex;
    align-items: center;
    margin-bottom: 0.2em;
  }
`;

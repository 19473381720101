import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  DangerButton,
  H2,
  SecondaryButton,
  SuccessButton,
  TextAreaWithLabelAndError,
} from "@collabodoc/component-library";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { PatientViewContext } from "./PatientView";
import { device } from "../../Content/Style/devices";
import { NoteClient } from "../../apiClient";

interface NoteModalProps {
  showModal: boolean;
  hideModal: () => void;
}

export const NoteModal = ({ showModal, hideModal }: NoteModalProps) => {
  const { patientId, patient, reFetchPatient } = useContext(PatientViewContext);
  const { accessToken } = useOidcAccessToken();
  const noteText = patient?.note?.text;
  const [tempNoteText, setTempNoteText] = useState(noteText);
  const [showDeleteNotesYesNoButtons, setShowDeleteNotesYesNoButtons] =
    useState(false);
  const handleDeleteClick = () => {
    setTempNoteText("");
    handleYesClick("");
  };

  const handleYesClick = (noteText: string) => {
    const client = new NoteClient({ accessToken });
    client.editNote(patientId, { noteText }).then(() => {
      hideModal();
      reFetchPatient();
      setShowDeleteNotesYesNoButtons(false);
    });
  };

  const handleCloseModal = () => {
    setTempNoteText(noteText);
    hideModal();
    setShowDeleteNotesYesNoButtons(false);
  };

  return (
    <Modal size={"lg"} show={showModal} onHide={() => handleCloseModal()}>
      <Modal.Header>
        <StyledH2>Privat anteckning</StyledH2>
      </Modal.Header>
      <Modal.Body>
        <TextSection>
          <TextAreaWithLabelAndError
            label={""}
            maxRows={12}
            minRows={12}
            handleChange={(e) => setTempNoteText(e)}
            value={tempNoteText}
            resizeHandles={false}
          />
        </TextSection>
        <small>
          Observera att en privat anteckning bara kan ses av dig själv.
        </small>
      </Modal.Body>
      <Footer>
        <LeftButtonsWrapper>
          <SecondaryButton onClick={() => handleCloseModal()}>
            Avbryt
          </SecondaryButton>
          {showDeleteNotesYesNoButtons ? (
            <DeleteNotesWrapper>
              Rensa anteckning?
              <YesNoButtonWrapper>
                <DangerButton
                  onClick={() => setShowDeleteNotesYesNoButtons(false)}
                >
                  Nej
                </DangerButton>
                <SuccessButton onClick={() => handleDeleteClick()}>
                  Ja
                </SuccessButton>
              </YesNoButtonWrapper>
            </DeleteNotesWrapper>
          ) : (
            <SecondaryButton
              onClick={() => setShowDeleteNotesYesNoButtons(true)}
            >
              Rensa
              <FontAwesomeIcon icon={faTrashCan} />
            </SecondaryButton>
          )}
        </LeftButtonsWrapper>
        <SuccessButton onClick={() => handleYesClick(tempNoteText)}>
          Spara
        </SuccessButton>
      </Footer>
    </Modal>
  );
};

export const StyledH2 = styled(H2)`
  margin: 0;
`;

const TextSection = styled.div`
  > textarea {
    border-radius: 5px;
  }
`;

export const Footer = styled(Modal.Footer)`
  display: flex;
  justify-content: space-between;

  @media ${device.tablet} {
    flex-direction: column-reverse;
    align-items: unset;
    flex-wrap: nowrap;
  }
`;

export const LeftButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: flex-start;
  gap: 12px;

  button {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }

  @media ${device.tablet} {
    justify-content: space-between;
    flex-direction: column-reverse;
    align-self: unset;
    gap: 8px;
  }
`;

const DeleteNotesWrapper = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;

  @media ${device.tablet} {
    flex-direction: column;
    align-items: unset;
  }
`;

const YesNoButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

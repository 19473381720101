import { USER_ROLES } from "../enums/enums";
import { RoleModel, UserRoles } from "../apiClient";

export const getSelectableRoles = (
  residentDepartmentRoles: RoleModel[],
  currentRoles: UserRoles[] | undefined,
  clinicRole: string | null,
  receiverRoleIds: number[]
): { text: string; id: number }[] => {
  const moreThanOneRoleInvolved =
    (currentRoles && currentRoles.length > 1) || receiverRoleIds.length > 1;
  const noRolesInvolved = !currentRoles?.length;

  if (noRolesInvolved) {
    return residentDepartmentRoles.map(({ role, roleName }) => ({
      text: roleName,
      id: role,
    }));
  }

  if (moreThanOneRoleInvolved)
    return residentDepartmentRoles
      .filter(
        ({ role }) =>
          clinicRole &&
          role !== USER_ROLES[clinicRole] &&
          (currentRoles?.includes(role) || receiverRoleIds.includes(role))
      )
      .map(({ role, roleName }) => ({
        text: roleName,
        id: role,
      }));

  if (!moreThanOneRoleInvolved)
    return residentDepartmentRoles
      .filter(({ role }) => currentRoles?.includes(role))
      .map(({ role, roleName }) => ({
        text: roleName,
        id: role,
      }));

  return residentDepartmentRoles.map(({ role, roleName }) => ({
    text: roleName,
    id: role,
  }));
};

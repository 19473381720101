import capio from "./capio";
import collabodocTheme from "./collabodocTheme";
import ptj from "./ptj";

export const getTheme = () => {
  const themeSetting = window.env.theme;

  let theme;

  switch (themeSetting.trim()) {
    case "capio":
      theme = capio;
      break;
    case "ptj":
      theme = ptj;
      break;
    default:
      theme = collabodocTheme;
  }

  return theme;
};

import {
  OidcUserInfo,
  useOidcAccessToken,
  useOidcUser,
} from "@axa-fr/react-oidc";
import {
  getClinicRole,
  getRequestInit,
  useFetchy,
} from "@collabodoc/component-library";
import React, { createContext, PropsWithChildren } from "react";
import { API_URLS } from "../enums/Urls";
import {
  CareCenterViewModel,
  ContactReasonModel,
  MeasureTemplateModel,
  ResidentDepartmentModel,
  TagModel,
  UserRoles,
} from "../apiClient";
import useHubConnection from "../hooks/useHubConnection";
import { HubConnectionState } from "@microsoft/signalr";
import VersionLoadingView from "../components/VersionLoadingView";

export interface CollabodocOidcUser extends OidcUserInfo {
  role: string;
}

interface GlobalContext {
  residentDepartments: ResidentDepartmentModel[];
  reFetchResidentDepartments: Function;
  careCenters: CareCenterViewModel[];
  contactReasons: ContactReasonModel[];
  selectableContactReasons: ContactReasonModel[];
  measureTemplates: MeasureTemplateModel[];
  oidcUser: CollabodocOidcUser;
  clinicRole: string | undefined;
  accessToken: string;
  hubConnectionState: HubConnectionState;
  defaultReceiver: UserRoles | undefined;
  showCareCenter: boolean;
  tags: TagModel[];
  reFetchTags: Function;
}

export const GlobalContext = createContext<GlobalContext>({} as GlobalContext);

export const GlobalContextProvider = ({ children }: PropsWithChildren) => {
  const { accessToken } = useOidcAccessToken();
  const { oidcUser }: { oidcUser: CollabodocOidcUser } = useOidcUser();

  const {
    data: residentDepartments,
    error: residentDepartmentError,
    doFetch: reFetchResidentDepartments,
  } = useFetchy<ResidentDepartmentModel[]>(
    API_URLS.RESIDENT_DEPARTMENTS,
    getRequestInit({ accessToken }),
  );

  const { data: careCenters, error: careCenterError } = useFetchy<
    CareCenterViewModel[]
  >(API_URLS.CARE_CENTERS, getRequestInit({ accessToken }));

  const hubConnectionState = useHubConnection(residentDepartments);

  const { data: contactReasons, error: contactReasonError } = useFetchy<
    ContactReasonModel[]
  >(API_URLS.CONTACT_REASONS, getRequestInit({ accessToken }));

  const { data: defaultReceiver, error: defaultReceiverError } = useFetchy<
    UserRoles | undefined
  >(API_URLS.DEFAULT_RECEIVER_ROLE, getRequestInit({ accessToken }));

  const url = new URL(API_URLS.MEASURE_TEMPLATES, document.baseURI);
  const clinicRole = getClinicRole(oidcUser?.role);
  if (clinicRole) {
    url.searchParams.append("role", clinicRole);
  }
  const { data: measureTemplates, error: measureTemplateError } = useFetchy<
    MeasureTemplateModel[]
  >(url.toString(), getRequestInit({ accessToken }));

  const { data: tags, doFetch: reFetchTags } = useFetchy<TagModel[]>(
    API_URLS.TAGS,
    getRequestInit({ accessToken }),
  );

  if (
    residentDepartmentError ||
    careCenterError ||
    contactReasonError ||
    defaultReceiverError ||
    measureTemplateError
  ) {
    throw new Error("Something went wrong when fetching data");
  }

  if (
    !oidcUser ||
    !residentDepartments ||
    !careCenters ||
    !contactReasons ||
    !measureTemplates
  ) {
    return <VersionLoadingView />;
  }

  const selectableContactReasons = (contactReasons || []).filter(
    (cr) => cr.isSelectable,
  );

  return (
    <GlobalContext.Provider
      value={{
        residentDepartments: residentDepartments || [],
        reFetchResidentDepartments,
        careCenters: careCenters || [],
        contactReasons: contactReasons || [],
        selectableContactReasons: selectableContactReasons,
        measureTemplates: measureTemplates || [],
        oidcUser,
        clinicRole: getClinicRole(oidcUser?.role),
        accessToken,
        hubConnectionState,
        defaultReceiver: defaultReceiver,
        showCareCenter: window.env.showCareCenter,
        tags: tags || [],
        reFetchTags,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

import { useOidc } from "@axa-fr/react-oidc";
import { getClinicRole, NavBar } from "@collabodoc/component-library";
import React, { useContext, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { GlobalContext } from "../context/GlobalContext";
import { EXTERNAL_URLS } from "../enums/Urls";
import CreateNotificationModal from "./CreateNotificationModal";
import Notifications from "./Notifications";
import styled, { useTheme } from "styled-components";
import PatientSearch from "./PatientSearch";
import { device, size } from "../Content/Style/devices";
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

const overrideLogo = window.env.overrideLogo;

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: Array<string>;

  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;

  prompt(): Promise<void>;
}
const NavMenu = () => {
  const theme = useTheme();
  const { logout, isAuthenticated } = useOidc();
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] =
    useState<BeforeInstallPromptEvent>();
  const { oidcUser } = useContext(GlobalContext);
  const clinicRole = getClinicRole(oidcUser?.role);
  const [showMobileSearchBar, setShowMobileSearchBar] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showCreateNotificationModal, setShowCreateNotificationModal] =
    useState(false);
  const searchIconRef = useRef(null);

  const isTablet = useMediaQuery({ query: device.tablet });

  function getPWADisplayMode() {
    const isStandalone = window.matchMedia(
      "(display-mode: standalone)",
    ).matches;
    if (
      ("standalone" in window.navigator && window.navigator["standalone"]) ||
      isStandalone
    ) {
      return "standalone";
    }
    return "browser";
  }

  useEffect(() => {
    const handler = (e: Event) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e as BeforeInstallPromptEvent);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("beforeinstallprompt", handler);
  }, []);

  const onInstallButtonClick = () => {
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };

  const logoutHandler = () => {
    if (isAuthenticated) {
      logout();
    }
  };

  const appMode = getPWADisplayMode();

  const handleToggleShowPatientSearch = () => {
    setShowMobileSearchBar(!showMobileSearchBar);
  };

  const handleNotificationClick = (e: React.MouseEvent) => {
    !showNotifications && e.stopPropagation();
    setShowNotifications(true);
  };

  const handleCreateNotificationModal = () => {
    setShowCreateNotificationModal(true);
  };

  let nonCollapsableItems = [
    {
      url: "",
      label: (
        <Notifications
          show={showNotifications}
          setShow={setShowNotifications}
        />
      ),
      handler: handleNotificationClick,
    },
  ];

  let items = [
    {
      label: "Skapa notis",
      handler: handleCreateNotificationModal,
    },
    {
      label: "Mitt konto",
      handler: () =>
        (window.location.href = `${window.env.authority}/manage?returnUrl=${window.location.href}`),
    },
    { label: "Logga ut", handler: logoutHandler },
  ];

  if (appMode !== "standalone" && (isMobile || isTablet) && supportsPWA) {
    items.unshift({
      label: "Installera App",
      handler: onInstallButtonClick,
    });
  }

  if (isTablet) {
    nonCollapsableItems.unshift({
      url: "",
      label: <FontAwesomeIcon icon={faMagnifyingGlass} ref={searchIconRef} />,
      handler: handleToggleShowPatientSearch,
    });
  } else {
    nonCollapsableItems.unshift({
      url: "",
      label: (
        <PatientSearch
          handleToggleShowPatientSearch={handleToggleShowPatientSearch}
        />
      ),
      handler: () => {},
    });
  }

  let logo, logoStyle;
  if (overrideLogo) {
    logo = theme.overrideLogo!;
    logoStyle = theme.overrideLogoStyle!;
  } else {
    logo = theme.logo;
    logoStyle = null;
  }

  return (
    <>
      <NavbarWrapper>
        <NavBar
          logo={logo}
          logoStyle={logoStyle}
          logohandler={() =>
            (window.location.href = EXTERNAL_URLS.CAREGIVER_PORTAL_URL)
          }
          nonCollapsableItems={nonCollapsableItems}
          items={items}
        />
        {isTablet && showMobileSearchBar && (
          <MobilePatientSearchWrapper>
            <PatientSearch
              handleToggleShowPatientSearch={handleToggleShowPatientSearch}
              setFocus={true}
              ref={searchIconRef}
            />
          </MobilePatientSearchWrapper>
        )}
      </NavbarWrapper>
      <CreateNotificationModal
        show={showCreateNotificationModal}
        setShow={setShowCreateNotificationModal}
      />
    </>
  );
};

const NavbarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.primary};

  .navbar-toggler {
    padding: 0 6px !important;
  }

  .nav-text,
  .navbar-text {
    padding: 0 6px !important;
    color: #fff;

    :hover {
      cursor: pointer;
    }

    @media ${device.tablet} {
      :hover {
        background-color: unset !important;
        color: #fff !important;
      }
    }
  }

  .nav-link {
    padding: 0 6px !important;
  }

  .navbar-nav {
    gap: 4px;
  }

  > div > nav {
    border: none;
    gap: 4px;

    > div {
      gap: 4px;

      > div {
        gap: 4px;
      }
    }
  }

  > div > nav > div > span {
    margin: 0;
  }

  > div > nav > div > div {
    flex: 1;
    align-items: center;
    justify-content: space-between;

    > div:first-child {
      display: flex;
      flex: 1;
      justify-content: center;

      @media ${device.tablet} {
        justify-content: flex-end;
      }

      > span {
        max-width: 500px;
        width: 100%;

        @media ${device.tablet} {
          width: unset;
        }

        @media screen and (min-width: ${size.tablet}) {
          :hover {
            background-color: transparent;
          }
        }
      }
    }
  }
`;

const MobilePatientSearchWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 0.75rem 0.75rem 0.75rem;

  > div {
    width: 100%;
  }
`;
export default NavMenu;

import { UserRoles } from "../apiClient";

const USER_ROLE_NAMES: {
  [RoleId: number]: string;
} = {
  1: "Läkare",
  2: "Sjuksköterska",
  4: "Sekreterare",
  5: "Fysioterapeut",
  6: "Psykolog",
  7: "Kurator",
  8: "Arbetsterapeut",
} as const;

const USER_ROLES: { [role: string]: UserRoles } = {
  DoctorUser: UserRoles.DoctorUser,
  NurseUser: UserRoles.NurseUser,
  RegistratorUser: UserRoles.RegistratorUser,
  SecretaryUser: UserRoles.SecretaryUser,
  PhysiotherapistUser: UserRoles.PhysiotherapistUser,
  PsychologistUser: UserRoles.PsychologistUser,
  CounselorUser: UserRoles.CounselorUser,
  OccupationalTherapistUser: UserRoles.OccupationalTherapistUser,
} as const;

const ACTIVE_TAB: { [TabName: string]: string } = {
  ALL_ISSUES_TAB: "allissues",
  SELECTED_ISSUE_TAB: "selectedissue",
} as const;

const ACTIVE_REMINDER_TAB: { [TabName: string]: string } = {
  ALL_REMINDERS_TAB: "allreminders",
  NEW_REMINDER_TAB: "newreminder",
} as const;

const FILTER_KEYS: { [KeyName: string]: string } = {
  TYPE: "types",
  OWNER: "owners",
  ROLES: "roles",
  STATUS: "statuses",
  CARE_CENTER_ID: "careCenterIds",
  RESIDENT_DEPARTMENT_ID: "residentDepartmentIds",
  UNIT_ID: "unitIds",
  PAGE: "page",
  CONTACT_REASON: "contactReasons",
  TAGS: "tags",
} as const;

export {
  ACTIVE_TAB,
  FILTER_KEYS,
  USER_ROLES,
  USER_ROLE_NAMES,
  ACTIVE_REMINDER_TAB,
};

import React from "react";
import { H5 } from "@collabodoc/component-library";
import styled, { keyframes } from "styled-components";
import packageJson from "../../package.json";
import CollabodocSpinner from "../Content/Images/logo/collabodoc_spinner.png";

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
`;

const LoadingViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  gap: 48px;
`;

const StyledImg = styled.img`
  width: 252px;
`;

const StyledCollabodocSpinner = styled.img`
  animation: ${rotate} 2s infinite linear;
  width: 40px;
  height: 40px;
`;

const FlexItem = styled.div`
  display: flex;
  margin-bottom: 2rem;
`;

const StyledH5 = styled(H5)`
  color: ${({ theme }) => theme.colors.primary};
`;

const VersionLoadingView = () => (
  <LoadingViewWrapper>
    <LogoWrapper>
      <StyledImg src={"/collabodoc_rondstod.svg"} alt={"Collabodoc Rondstöd"} />
      <StyledCollabodocSpinner src={CollabodocSpinner} />
    </LogoWrapper>
    <FlexItem>
      <StyledH5>{`Version ${packageJson.version}`}</StyledH5>
    </FlexItem>
  </LoadingViewWrapper>
);

export default VersionLoadingView;
